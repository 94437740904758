import React from "react";
import { isMobile } from "react-device-detect";
import Main from "../../shared/components/UIElements/Main/Main";
import PageHeaderImage from "../../shared/components/UIElements/PageHeaderImage/PageHeaderImage";
import AboutScreenHeaderImage from "../../shared/images/pageHeaders/about.jpg";

import "./AboutScreen.css";

const AboutScreen = () => {
  return (
    <React.Fragment>
      <PageHeaderImage image={AboutScreenHeaderImage} title="ABOUT ME" />
      <Main>
        <section id="about">
          <div className="about-inner-wrapper">
            <div className="about__content">
              <h2>About Myself</h2>

              <p>
                Hello! My name is Jimmy Oliva and I'm a self-taught Front End
                Developer located in Huntington, New York. I have a passion for
                building that’s fueled by a goal to improve my skillset to the
                highest level possible.
              </p>
              <p>
                I love to learn new things and consider myself a perfectionist,
                leaving no stone unturned. I’m competitive by nature, which I
                attribute to years of playing baseball growing up. As a kid, I
                also discovered a love for building, whether it was with Legos
                or creating a whole new world in Minecraft (both of which I
                admittedly still love today). I always enjoyed the challenge of
                creating something new that was superior to my previous build. I
                find myself applying this same practice in my work today with
                programming, and remain competitive with both myself and my
                peers.
              </p>
              <p>
                Standard vanilla HTML5, CSS3, and Javascript are my roots, but
                I've fallen in love with primarily using React JS & Redux with
                styled components and SASS. I’ve used standard backend
                technologies such as Node JS/Express, MongoDB/Mongoose, and
                Firestore in the past, and with the right guidance, I'm
                confident that any technology thrown my way will be something I
                can master!
              </p>
            </div>
          </div>
          <div className="about__graphic">{aboutGraphic}</div>
        </section>
      </Main>
    </React.Fragment>
  );
};

const aboutGraphic = (
  <svg
    id="ad4a33ce-6f17-44c6-b9ef-ef82a48e21c2"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 451.82 528.25"
  >
    <defs></defs>
    <path
      d="M111,178.82a1.77,1.77,0,0,0-.7-1.26,2.51,2.51,0,0,0-1.56-.46,2.67,2.67,0,0,0-1.16.23,1.86,1.86,0,0,0-.78.62,1.54,1.54,0,0,0-.29.91,1.33,1.33,0,0,0,.16.65,1.71,1.71,0,0,0,.44.49,3.57,3.57,0,0,0,.61.34,4.23,4.23,0,0,0,.68.24l1,.29a8.14,8.14,0,0,1,.91.31,4,4,0,0,1,.83.49,2.31,2.31,0,0,1,.6.72,2.12,2.12,0,0,1,.23,1,2.43,2.43,0,0,1-.39,1.36,2.68,2.68,0,0,1-1.13,1,4.56,4.56,0,0,1-3.43,0,2.63,2.63,0,0,1-1.12-.89,2.52,2.52,0,0,1-.46-1.31h.89a1.58,1.58,0,0,0,.37.94,1.91,1.91,0,0,0,.84.59,3,3,0,0,0,1.15.2,3.33,3.33,0,0,0,1.26-.23,2,2,0,0,0,.87-.67,1.6,1.6,0,0,0,.31-1,1.34,1.34,0,0,0-.24-.81,2,2,0,0,0-.7-.55,8,8,0,0,0-1-.39l-1.13-.33a4.23,4.23,0,0,1-1.73-.92,2,2,0,0,1-.61-1.5,2.24,2.24,0,0,1,.41-1.34,2.8,2.8,0,0,1,1.1-.9,3.65,3.65,0,0,1,1.57-.33,3.58,3.58,0,0,1,1.57.33,2.61,2.61,0,0,1,1.07.89,2.28,2.28,0,0,1,.43,1.28Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M114.09,185.91h-.91l3.44-9.45h.92l3.44,9.45h-.9l-3-8.33h-.06Zm.65-3.62h4.69v.77h-4.69Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M122.62,185.91h-.91l3.44-9.45h.93l3.43,9.45h-.9l-3-8.33h-.07Zm.65-3.62H128v.77h-4.69Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M136.27,178.82a1.74,1.74,0,0,0-.7-1.26,2.5,2.5,0,0,0-1.55-.46,2.72,2.72,0,0,0-1.17.23,1.86,1.86,0,0,0-.78.62,1.53,1.53,0,0,0-.28.91,1.23,1.23,0,0,0,.16.65,1.54,1.54,0,0,0,.43.49,4,4,0,0,0,.61.34,4.6,4.6,0,0,0,.68.24l1,.29a8,8,0,0,1,.9.31,4,4,0,0,1,.83.49,2.31,2.31,0,0,1,.6.72,2,2,0,0,1,.23,1,2.43,2.43,0,0,1-.39,1.36,2.68,2.68,0,0,1-1.13,1,4.56,4.56,0,0,1-3.43,0,2.7,2.7,0,0,1-1.12-.89,2.42,2.42,0,0,1-.45-1.31h.88a1.65,1.65,0,0,0,.37.94,2,2,0,0,0,.84.59,3.07,3.07,0,0,0,1.16.2,3.24,3.24,0,0,0,1.25-.23,2,2,0,0,0,.87-.67,1.61,1.61,0,0,0,.32-1,1.35,1.35,0,0,0-.25-.81,2,2,0,0,0-.7-.55,7.46,7.46,0,0,0-1-.39l-1.13-.33a4.23,4.23,0,0,1-1.73-.92,2,2,0,0,1-.61-1.5,2.31,2.31,0,0,1,.41-1.34,2.76,2.76,0,0,1,1.11-.9,3.62,3.62,0,0,1,1.57-.33,3.51,3.51,0,0,1,1.56.33,2.7,2.7,0,0,1,1.08.89,2.35,2.35,0,0,1,.42,1.28Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M111.74,219.78v-9.46h3.19a3.28,3.28,0,0,1,1.54.33,2.07,2.07,0,0,1,.92.88,2.52,2.52,0,0,1,.31,1.27,2.39,2.39,0,0,1-.2,1,1.81,1.81,0,0,1-.54.68,2.34,2.34,0,0,1-.76.37v.07a2,2,0,0,1,.89.32,2.31,2.31,0,0,1,.7.81,2.78,2.78,0,0,1,0,2.51,2.21,2.21,0,0,1-1,.89,3.9,3.9,0,0,1-1.68.32Zm.86-5.19H115a1.92,1.92,0,0,0,1-.23,1.63,1.63,0,0,0,.66-.64,1.77,1.77,0,0,0,.23-.92,1.62,1.62,0,0,0-.49-1.23,2,2,0,0,0-1.45-.47H112.6Zm0,4.41h2.5a2.34,2.34,0,0,0,1.62-.48,1.6,1.6,0,0,0,.52-1.22,1.87,1.87,0,0,0-1-1.68,2.33,2.33,0,0,0-1.11-.26H112.6Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M120.15,219.78v-9.46h5.49v.78H121v3.56h4.33v.77H121V219h4.71v.78Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M127.87,210.32h1l3.43,8.24h.09l3.43-8.24h1v9.46H136v-7.63h-.07l-3.19,7.63h-.79l-3.19-7.63h-.07v7.63h-.81Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M24.35,253.64v-9.45h3a3.55,3.55,0,0,1,1.69.36,2.46,2.46,0,0,1,1,1,3.07,3.07,0,0,1,.34,1.47,3,3,0,0,1-.34,1.46,2.36,2.36,0,0,1-1,1,3.53,3.53,0,0,1-1.67.36h-2.6V249h2.57a2.75,2.75,0,0,0,1.23-.24,1.68,1.68,0,0,0,.74-.7A2.25,2.25,0,0,0,29.6,247a2.29,2.29,0,0,0-.24-1.08,1.72,1.72,0,0,0-.74-.72,2.68,2.68,0,0,0-1.24-.26H25.22v8.68Zm4.17-4.26,2.33,4.26h-1l-2.3-4.26Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M35.18,253.79a3.11,3.11,0,0,1-1.72-.46A3.07,3.07,0,0,1,32.34,252a4.39,4.39,0,0,1-.39-1.9,4.46,4.46,0,0,1,.39-1.91,3.12,3.12,0,0,1,1.1-1.3,2.76,2.76,0,0,1,1.61-.48,3.05,3.05,0,0,1,1.12.21,2.87,2.87,0,0,1,1,.65,3,3,0,0,1,.67,1.08,4.27,4.27,0,0,1,.25,1.55v.4H32.51v-.72h4.7a2.71,2.71,0,0,0-.27-1.23,2.15,2.15,0,0,0-.76-.87,2,2,0,0,0-1.13-.32,2,2,0,0,0-1.18.36,2.47,2.47,0,0,0-.8.95,3,3,0,0,0-.3,1.3v.43a3.46,3.46,0,0,0,.3,1.48,2.2,2.2,0,0,0,.83,1,2.34,2.34,0,0,0,1.28.35,2.19,2.19,0,0,0,.88-.16,1.7,1.7,0,0,0,.64-.42,1.76,1.76,0,0,0,.4-.59l.78.26a2.36,2.36,0,0,1-.53.82,2.56,2.56,0,0,1-.91.61A3.33,3.33,0,0,1,35.18,253.79Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M44.62,248.11l-.75.21a2.16,2.16,0,0,0-.31-.58,1.45,1.45,0,0,0-.53-.41,2,2,0,0,0-.81-.15,1.92,1.92,0,0,0-1.18.34,1,1,0,0,0-.46.88.91.91,0,0,0,.32.74,2.25,2.25,0,0,0,1,.45l1.07.27a2.65,2.65,0,0,1,1.34.68,1.65,1.65,0,0,1,.44,1.19,1.78,1.78,0,0,1-.33,1.06,2.17,2.17,0,0,1-.92.73,3.33,3.33,0,0,1-1.38.27,3,3,0,0,1-1.71-.47,2.07,2.07,0,0,1-.86-1.35l.79-.2a1.6,1.6,0,0,0,.6,1,2,2,0,0,0,1.17.32,2.12,2.12,0,0,0,1.31-.37,1.12,1.12,0,0,0,.49-.92,1,1,0,0,0-.29-.72,1.78,1.78,0,0,0-.87-.43l-1.16-.28a2.58,2.58,0,0,1-1.36-.7,1.68,1.68,0,0,1-.45-1.19,1.75,1.75,0,0,1,.32-1,2.13,2.13,0,0,1,.88-.7,3,3,0,0,1,1.27-.26,2.49,2.49,0,0,1,1.56.45A2.38,2.38,0,0,1,44.62,248.11Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M46.51,256.3v-9.75h.8v1.35h.1a3.57,3.57,0,0,1,.4-.65,2.17,2.17,0,0,1,1.77-.8,2.66,2.66,0,0,1,1.53.46,3,3,0,0,1,1,1.29,4.6,4.6,0,0,1,.37,1.92,4.76,4.76,0,0,1-.37,1.92,3.1,3.1,0,0,1-1,1.29,2.53,2.53,0,0,1-1.52.46,2.33,2.33,0,0,1-1.08-.23,2,2,0,0,1-.69-.57,3.05,3.05,0,0,1-.41-.66h-.07v4Zm.81-6.2a4.21,4.21,0,0,0,.26,1.52,2.38,2.38,0,0,0,.74,1,2,2,0,0,0,2.36,0,2.38,2.38,0,0,0,.75-1.06,4.12,4.12,0,0,0,.25-1.49,4,4,0,0,0-.25-1.47,2.36,2.36,0,0,0-.74-1,1.84,1.84,0,0,0-1.2-.39,1.93,1.93,0,0,0-1.18.37,2.25,2.25,0,0,0-.73,1A4.1,4.1,0,0,0,47.32,250.1Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M57.07,253.79a2.88,2.88,0,0,1-1.62-.47A3.19,3.19,0,0,1,54.34,252a4.39,4.39,0,0,1-.39-1.9,4.45,4.45,0,0,1,.39-1.92,3.19,3.19,0,0,1,1.11-1.29,3,3,0,0,1,3.24,0,3.17,3.17,0,0,1,1.1,1.29,4.31,4.31,0,0,1,.4,1.92,4.25,4.25,0,0,1-.4,1.9,3.09,3.09,0,0,1-1.1,1.29A2.88,2.88,0,0,1,57.07,253.79Zm0-.75a2,2,0,0,0,1.25-.4,2.49,2.49,0,0,0,.78-1.06,4,4,0,0,0,.26-1.45,4,4,0,0,0-.26-1.46,2.59,2.59,0,0,0-.78-1.07,2.15,2.15,0,0,0-2.5,0,2.59,2.59,0,0,0-.78,1.07,3.83,3.83,0,0,0-.27,1.46,3.78,3.78,0,0,0,.27,1.45,2.49,2.49,0,0,0,.78,1.06A2,2,0,0,0,57.07,253Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M62.82,249.21v4.43H62v-7.09h.79v1.11h.08a1.92,1.92,0,0,1,.78-.87,2.39,2.39,0,0,1,1.3-.33,2.55,2.55,0,0,1,1.26.3,2.1,2.1,0,0,1,.85.87,3.07,3.07,0,0,1,.3,1.42v4.59h-.82V249.1a1.89,1.89,0,0,0-.49-1.38,1.71,1.71,0,0,0-1.3-.51,2,2,0,0,0-1,.24,1.74,1.74,0,0,0-.68.69A2.14,2.14,0,0,0,62.82,249.21Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M74.29,248.11l-.75.21a2.16,2.16,0,0,0-.31-.58,1.54,1.54,0,0,0-.53-.41,2,2,0,0,0-.81-.15,1.92,1.92,0,0,0-1.18.34,1,1,0,0,0-.46.88.94.94,0,0,0,.31.74,2.36,2.36,0,0,0,1,.45l1.07.27a2.65,2.65,0,0,1,1.34.68,1.65,1.65,0,0,1,.44,1.19,1.78,1.78,0,0,1-.33,1.06,2.17,2.17,0,0,1-.92.73,3.33,3.33,0,0,1-1.38.27,3,3,0,0,1-1.71-.47,2.07,2.07,0,0,1-.86-1.35l.79-.2a1.63,1.63,0,0,0,.59,1,2,2,0,0,0,1.18.32,2.12,2.12,0,0,0,1.31-.37,1.11,1.11,0,0,0,.48-.92,1,1,0,0,0-.28-.72,1.78,1.78,0,0,0-.87-.43l-1.16-.28a2.58,2.58,0,0,1-1.36-.7,1.68,1.68,0,0,1-.45-1.19,1.75,1.75,0,0,1,.32-1,2.13,2.13,0,0,1,.88-.7,3,3,0,0,1,1.27-.26,2.49,2.49,0,0,1,1.56.45A2.38,2.38,0,0,1,74.29,248.11Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M76.62,245.33a.59.59,0,0,1-.43-.17.56.56,0,0,1-.18-.42.58.58,0,0,1,.18-.42.63.63,0,0,1,.43-.17.64.64,0,0,1,.44.17.58.58,0,0,1,.18.42.56.56,0,0,1-.18.42A.6.6,0,0,1,76.62,245.33Zm-.42,8.31v-7.09H77v7.09Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M84.7,246.55l-2.59,7.09h-.88l-2.59-7.09h.89l2.11,6h.06l2.11-6Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M88.91,253.79a3.11,3.11,0,0,1-1.72-.46A3.07,3.07,0,0,1,86.07,252a4.39,4.39,0,0,1-.39-1.9,4.46,4.46,0,0,1,.39-1.91,3.12,3.12,0,0,1,1.1-1.3,2.76,2.76,0,0,1,1.61-.48,3.05,3.05,0,0,1,1.12.21,2.87,2.87,0,0,1,1,.65,3.17,3.17,0,0,1,.68,1.08,4.5,4.5,0,0,1,.25,1.55v.4H86.25v-.72h4.69a2.71,2.71,0,0,0-.27-1.23,2.15,2.15,0,0,0-.76-.87,2,2,0,0,0-1.13-.32,2,2,0,0,0-1.18.36,2.47,2.47,0,0,0-.8.95,3,3,0,0,0-.3,1.3v.43a3.46,3.46,0,0,0,.3,1.48,2.2,2.2,0,0,0,.83,1,2.34,2.34,0,0,0,1.28.35,2.19,2.19,0,0,0,.88-.16,1.7,1.7,0,0,0,.64-.42,1.76,1.76,0,0,0,.4-.59l.78.26a2.22,2.22,0,0,1-.53.82,2.56,2.56,0,0,1-.91.61A3.33,3.33,0,0,1,88.91,253.79Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M100.19,253.64H97.42v-9.45h2.93a4.58,4.58,0,0,1,2.32.56,3.73,3.73,0,0,1,1.47,1.62,6.5,6.5,0,0,1,0,5.07,3.7,3.7,0,0,1-1.52,1.63A4.84,4.84,0,0,1,100.19,253.64Zm-1.91-.77h1.85a4,4,0,0,0,2.05-.48,3.09,3.09,0,0,0,1.23-1.37,5.73,5.73,0,0,0,0-4.23,3,3,0,0,0-1.19-1.36,3.7,3.7,0,0,0-1.93-.47h-2Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M109.44,253.79a3.11,3.11,0,0,1-1.72-.46A3.07,3.07,0,0,1,106.6,252a4.39,4.39,0,0,1-.39-1.9,4.46,4.46,0,0,1,.39-1.91,3.18,3.18,0,0,1,1.09-1.3,2.81,2.81,0,0,1,1.62-.48,3,3,0,0,1,1.11.21,2.81,2.81,0,0,1,1,.65,3,3,0,0,1,.67,1.08,4.27,4.27,0,0,1,.25,1.55v.4h-5.54v-.72h4.7a2.71,2.71,0,0,0-.27-1.23,2.23,2.23,0,0,0-.76-.87,2,2,0,0,0-1.13-.32,2,2,0,0,0-1.18.36,2.39,2.39,0,0,0-.8.95,3,3,0,0,0-.3,1.3v.43a3.46,3.46,0,0,0,.3,1.48,2.2,2.2,0,0,0,.83,1,2.31,2.31,0,0,0,1.28.35,2.19,2.19,0,0,0,.88-.16,1.7,1.7,0,0,0,.64-.42,1.76,1.76,0,0,0,.4-.59l.78.26a2.36,2.36,0,0,1-.53.82,2.65,2.65,0,0,1-.91.61A3.33,3.33,0,0,1,109.44,253.79Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M118.88,248.11l-.75.21a2.16,2.16,0,0,0-.31-.58,1.54,1.54,0,0,0-.53-.41,2,2,0,0,0-.81-.15,1.92,1.92,0,0,0-1.18.34,1,1,0,0,0-.46.88.94.94,0,0,0,.31.74,2.36,2.36,0,0,0,1,.45l1.07.27a2.65,2.65,0,0,1,1.34.68,1.7,1.7,0,0,1,.44,1.19,1.78,1.78,0,0,1-.33,1.06,2.17,2.17,0,0,1-.92.73,3.33,3.33,0,0,1-1.38.27,3,3,0,0,1-1.71-.47,2.07,2.07,0,0,1-.86-1.35l.79-.2a1.63,1.63,0,0,0,.59,1,2,2,0,0,0,1.18.32,2.12,2.12,0,0,0,1.31-.37,1.11,1.11,0,0,0,.48-.92,1,1,0,0,0-.28-.72,1.78,1.78,0,0,0-.87-.43l-1.16-.28a2.58,2.58,0,0,1-1.36-.7,1.68,1.68,0,0,1-.45-1.19,1.75,1.75,0,0,1,.32-1,2.07,2.07,0,0,1,.88-.7,3,3,0,0,1,1.27-.26,2.49,2.49,0,0,1,1.56.45A2.38,2.38,0,0,1,118.88,248.11Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M121.21,245.33a.59.59,0,0,1-.43-.17.56.56,0,0,1-.18-.42.58.58,0,0,1,.18-.42.63.63,0,0,1,.43-.17.64.64,0,0,1,.44.17.58.58,0,0,1,.18.42.56.56,0,0,1-.18.42A.6.6,0,0,1,121.21,245.33Zm-.42,8.31v-7.09h.83v7.09Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M126.5,256.45a3.81,3.81,0,0,1-1.27-.19,2.6,2.6,0,0,1-.91-.51,2.37,2.37,0,0,1-.59-.72l.68-.45a2.44,2.44,0,0,0,.41.53,1.94,1.94,0,0,0,.67.43,2.7,2.7,0,0,0,1,.17,2.39,2.39,0,0,0,1.53-.47,1.78,1.78,0,0,0,.57-1.46v-1.6h-.07a2.55,2.55,0,0,1-.41.64,2.14,2.14,0,0,1-.7.54,2.43,2.43,0,0,1-1.07.21,2.77,2.77,0,0,1-1.51-.42,2.9,2.9,0,0,1-1-1.2,4.33,4.33,0,0,1-.38-1.87,4.62,4.62,0,0,1,.37-1.89,3.07,3.07,0,0,1,1-1.28,2.67,2.67,0,0,1,1.54-.46,2.33,2.33,0,0,1,1.08.23,2.29,2.29,0,0,1,.69.57,3.09,3.09,0,0,1,.4.65h.09v-1.35h.8v7.28a2.69,2.69,0,0,1-.38,1.48,2.33,2.33,0,0,1-1.05.86A3.75,3.75,0,0,1,126.5,256.45Zm0-3.63a2.06,2.06,0,0,0,1.17-.33,2.1,2.1,0,0,0,.74-1,3.91,3.91,0,0,0,.26-1.48,4,4,0,0,0-.26-1.47,2.27,2.27,0,0,0-.73-1,1.93,1.93,0,0,0-1.18-.37,1.84,1.84,0,0,0-1.2.39,2.32,2.32,0,0,0-.74,1,3.89,3.89,0,0,0-.25,1.44,3.78,3.78,0,0,0,.25,1.44,2.16,2.16,0,0,0,.75,1A2,2,0,0,0,126.45,252.82Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M132.43,249.21v4.43h-.82v-7.09h.8v1.11h.07a2,2,0,0,1,.78-.87,2.43,2.43,0,0,1,1.31-.33,2.55,2.55,0,0,1,1.26.3,2.07,2.07,0,0,1,.84.87,3.07,3.07,0,0,1,.3,1.42v4.59h-.82V249.1a1.93,1.93,0,0,0-.48-1.38,1.73,1.73,0,0,0-1.31-.51,2,2,0,0,0-1,.24,1.76,1.76,0,0,0-.69.69A2.25,2.25,0,0,0,132.43,249.21Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M80,287.51v-9.46h3.18a3.22,3.22,0,0,1,1.54.33,2.14,2.14,0,0,1,.93.88,2.52,2.52,0,0,1,.31,1.27,2.39,2.39,0,0,1-.2,1,1.74,1.74,0,0,1-.54.68,2.34,2.34,0,0,1-.76.37v.08a1.76,1.76,0,0,1,.89.32,2.19,2.19,0,0,1,.7.8,2.55,2.55,0,0,1,.28,1.24,2.59,2.59,0,0,1-.32,1.28,2.23,2.23,0,0,1-1,.88,3.82,3.82,0,0,1-1.67.32Zm.86-5.19h2.4a1.92,1.92,0,0,0,1-.23,1.67,1.67,0,0,0,.66-.63,1.89,1.89,0,0,0,.23-.93,1.63,1.63,0,0,0-.49-1.23,2,2,0,0,0-1.46-.47H80.89Zm0,4.41h2.5a2.34,2.34,0,0,0,1.62-.48,1.6,1.6,0,0,0,.52-1.22,1.87,1.87,0,0,0-1-1.68,2.33,2.33,0,0,0-1.11-.26H80.89Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M91,287.66a2.89,2.89,0,0,1-1.63-.47,3.17,3.17,0,0,1-1.1-1.29,4.39,4.39,0,0,1-.39-1.9,4.45,4.45,0,0,1,.39-1.92,3.13,3.13,0,0,1,1.1-1.3,3,3,0,0,1,1.63-.46,2.88,2.88,0,0,1,1.62.47,3.24,3.24,0,0,1,1.1,1.29,4.45,4.45,0,0,1,.4,1.92,4.39,4.39,0,0,1-.4,1.9,3.09,3.09,0,0,1-1.1,1.29A2.88,2.88,0,0,1,91,287.66Zm0-.76a2,2,0,0,0,1.25-.39,2.56,2.56,0,0,0,.78-1.06,4.2,4.2,0,0,0,0-2.91,2.53,2.53,0,0,0-.78-1.07,2.15,2.15,0,0,0-2.5,0,2.37,2.37,0,0,0-.78,1.07,4.06,4.06,0,0,0,0,2.91,2.39,2.39,0,0,0,.78,1.06A2,2,0,0,0,91,286.9Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M98.71,287.66a2.91,2.91,0,0,1-1.63-.47A3.24,3.24,0,0,1,96,285.9a4.39,4.39,0,0,1-.39-1.9,4.45,4.45,0,0,1,.39-1.92,3.21,3.21,0,0,1,1.1-1.3,3,3,0,0,1,1.63-.46,2.88,2.88,0,0,1,1.62.47,3.17,3.17,0,0,1,1.1,1.29,4.31,4.31,0,0,1,.4,1.92,4.25,4.25,0,0,1-.4,1.9,3.09,3.09,0,0,1-1.1,1.29A2.88,2.88,0,0,1,98.71,287.66Zm0-.76a2,2,0,0,0,1.25-.39,2.56,2.56,0,0,0,.78-1.06,4.2,4.2,0,0,0,0-2.91,2.53,2.53,0,0,0-.78-1.07,2.15,2.15,0,0,0-2.5,0,2.53,2.53,0,0,0-.78,1.07,4.06,4.06,0,0,0,0,2.91,2.56,2.56,0,0,0,.78,1.06A2,2,0,0,0,98.71,286.9Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M106.43,280.42v.71H103v-.71Zm-2.33-1.7h.83v6.94a1.36,1.36,0,0,0,.15.69.82.82,0,0,0,.4.36,1.27,1.27,0,0,0,.52.1l.27,0,.21,0,.17.74-.29.09a2.68,2.68,0,0,1-.44,0,2,2,0,0,1-.87-.19,1.76,1.76,0,0,1-.68-.59,1.71,1.71,0,0,1-.27-1Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M112.93,282l-.76.22a1.71,1.71,0,0,0-.31-.58,1.45,1.45,0,0,0-.53-.41,1.9,1.9,0,0,0-.8-.15,2,2,0,0,0-1.19.34,1.06,1.06,0,0,0-.46.88,1,1,0,0,0,.32.74,2.25,2.25,0,0,0,1,.45l1.08.26a2.7,2.7,0,0,1,1.33.69,1.64,1.64,0,0,1,.45,1.18,1.81,1.81,0,0,1-.33,1.07,2.28,2.28,0,0,1-.93.73,3.28,3.28,0,0,1-1.37.27,3,3,0,0,1-1.72-.47,2.13,2.13,0,0,1-.86-1.36l.79-.19a1.55,1.55,0,0,0,.6.95,1.91,1.91,0,0,0,1.17.33,2.17,2.17,0,0,0,1.31-.37,1.09,1.09,0,0,0,.49-.92,1,1,0,0,0-.29-.72,1.7,1.7,0,0,0-.87-.43l-1.15-.28a2.65,2.65,0,0,1-1.37-.7,1.67,1.67,0,0,1-.44-1.19,1.75,1.75,0,0,1,.31-1,2.28,2.28,0,0,1,.88-.7,3.21,3.21,0,0,1,1.28-.25,2.31,2.31,0,0,1,2.4,1.65Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M117.62,280.42v.71h-3.38v-.71Zm-2.33-1.7h.83v6.94a1.36,1.36,0,0,0,.15.69.91.91,0,0,0,.4.36,1.3,1.3,0,0,0,.52.1l.28,0,.2,0,.18.74-.3.09a2.63,2.63,0,0,1-.43,0,2,2,0,0,1-.87-.19,1.79,1.79,0,0,1-.69-.59,1.71,1.71,0,0,1-.27-1Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M119.49,287.51v-7.09h.8v1.09h0a1.8,1.8,0,0,1,.75-.87,2.2,2.2,0,0,1,1.19-.33h.44v.83l-.2,0-.3,0a2,2,0,0,0-1,.24,1.68,1.68,0,0,0-.68.64,1.76,1.76,0,0,0-.25.94v4.58Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M126.1,287.67a2.64,2.64,0,0,1-1.17-.25,2.08,2.08,0,0,1-.85-.73,2.16,2.16,0,0,1-.31-1.16,1.86,1.86,0,0,1,.2-.89,1.58,1.58,0,0,1,.56-.59,3.3,3.3,0,0,1,.87-.36,7.82,7.82,0,0,1,1.09-.21l1-.13a1.84,1.84,0,0,0,.63-.18.4.4,0,0,0,.22-.38v-.17a1.51,1.51,0,0,0-.43-1.14,1.71,1.71,0,0,0-1.24-.42,2.1,2.1,0,0,0-1.25.34,1.88,1.88,0,0,0-.68.79l-.78-.28a2.24,2.24,0,0,1,.67-.93,2.63,2.63,0,0,1,1-.5,3.51,3.51,0,0,1,1.07-.16,3.59,3.59,0,0,1,.84.11,2.41,2.41,0,0,1,.82.36,2,2,0,0,1,.62.73,2.68,2.68,0,0,1,.23,1.17v4.82h-.82v-1.12h0a2.4,2.4,0,0,1-.44.61,2.44,2.44,0,0,1-.73.48A2.49,2.49,0,0,1,126.1,287.67Zm.11-.75a2.19,2.19,0,0,0,1.14-.29,2,2,0,0,0,.74-.78,2.22,2.22,0,0,0,.26-1.06v-1a.68.68,0,0,1-.31.16,2.7,2.7,0,0,1-.49.12,5.43,5.43,0,0,1-.56.09l-.5.07a4.28,4.28,0,0,0-1,.23,1.48,1.48,0,0,0-.65.43,1.07,1.07,0,0,0-.23.7,1.19,1.19,0,0,0,.46,1A1.84,1.84,0,0,0,126.21,286.92Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M131.34,290.17v-9.75h.8v1.35h.1a3.57,3.57,0,0,1,.4-.65,2.17,2.17,0,0,1,.69-.57,2.28,2.28,0,0,1,1.08-.23,2.59,2.59,0,0,1,1.53.46,3,3,0,0,1,1,1.28,4.6,4.6,0,0,1,.37,1.92,4.81,4.81,0,0,1-.37,1.93,3,3,0,0,1-1,1.28,2.54,2.54,0,0,1-1.52.47,2.33,2.33,0,0,1-1.08-.23,2.09,2.09,0,0,1-.69-.58,2.64,2.64,0,0,1-.41-.66h-.07v4Zm.81-6.2a4.21,4.21,0,0,0,.26,1.52,2.38,2.38,0,0,0,.74,1,2,2,0,0,0,2.36,0,2.41,2.41,0,0,0,.75-1.05,4.12,4.12,0,0,0,.25-1.49,4.07,4.07,0,0,0-.25-1.48,2.41,2.41,0,0,0-.74-1,1.89,1.89,0,0,0-1.2-.38,1.87,1.87,0,0,0-1.18.37,2.27,2.27,0,0,0-.73,1A4.15,4.15,0,0,0,132.15,284Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M77.68,321.37h-.9l3.44-9.45h.92l3.44,9.45h-.9l-3-8.33h-.06Zm.65-3.62H83v.78H78.33Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M86.85,316.94v4.43H86v-7.09h.8v1.12h.07a2,2,0,0,1,.78-.88,2.43,2.43,0,0,1,1.31-.33,2.55,2.55,0,0,1,1.26.3,2.07,2.07,0,0,1,.84.87,3.07,3.07,0,0,1,.3,1.42v4.59h-.82v-4.54a1.93,1.93,0,0,0-.48-1.38,1.73,1.73,0,0,0-1.31-.51,2,2,0,0,0-1,.24,1.76,1.76,0,0,0-.69.69A2.25,2.25,0,0,0,86.85,316.94Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M94,313.06a.59.59,0,0,1-.43-.17.54.54,0,0,1-.18-.42.54.54,0,0,1,.18-.41.6.6,0,0,1,.43-.18.6.6,0,0,1,.44.18.54.54,0,0,1,.18.41.54.54,0,0,1-.18.42A.6.6,0,0,1,94,313.06Zm-.42,8.31v-7.09h.83v7.09Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M96.58,321.37v-7.09h.8v1.09h.07a1.76,1.76,0,0,1,.71-.86,2.18,2.18,0,0,1,1.19-.32,1.9,1.9,0,0,1,1.92,1.29h.06a1.94,1.94,0,0,1,.78-.94,2.4,2.4,0,0,1,1.32-.35,2,2,0,0,1,1.56.62,2.6,2.6,0,0,1,.57,1.81v4.75h-.82v-4.75a1.67,1.67,0,0,0-.43-1.26,1.53,1.53,0,0,0-1.13-.42,1.58,1.58,0,0,0-1.25.5,1.87,1.87,0,0,0-.44,1.27v4.66h-.84v-4.82a1.64,1.64,0,0,0-.4-1.16,1.45,1.45,0,0,0-1.13-.45,1.64,1.64,0,0,0-.88.24,1.74,1.74,0,0,0-.62.67,2.21,2.21,0,0,0-.22,1v4.54Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M109.71,321.54a2.68,2.68,0,0,1-1.17-.26,1.92,1.92,0,0,1-.84-.73,2,2,0,0,1-.32-1.16,1.82,1.82,0,0,1,.2-.88,1.58,1.58,0,0,1,.56-.59,3,3,0,0,1,.87-.37,8,8,0,0,1,1.1-.21l1-.13a1.66,1.66,0,0,0,.63-.17.41.41,0,0,0,.22-.39v-.16a1.52,1.52,0,0,0-.43-1.15,1.71,1.71,0,0,0-1.24-.42,2.17,2.17,0,0,0-1.25.34,1.81,1.81,0,0,0-.67.8l-.79-.29a2.48,2.48,0,0,1,.67-.93,2.63,2.63,0,0,1,1-.5,3.89,3.89,0,0,1,1.07-.15,3.56,3.56,0,0,1,.84.1,2.44,2.44,0,0,1,.82.37,1.83,1.83,0,0,1,.62.72,2.69,2.69,0,0,1,.23,1.18v4.81H112v-1.12h0a2.18,2.18,0,0,1-.44.61,2.28,2.28,0,0,1-.73.49A2.67,2.67,0,0,1,109.71,321.54Zm.11-.76a2.1,2.1,0,0,0,1.14-.29,2,2,0,0,0,.74-.77,2.26,2.26,0,0,0,.26-1.07v-1a.8.8,0,0,1-.31.16l-.49.12-.56.09-.49.06a4.94,4.94,0,0,0-1,.23,1.5,1.5,0,0,0-.65.44,1.05,1.05,0,0,0-.23.7,1.19,1.19,0,0,0,.47,1A1.79,1.79,0,0,0,109.82,320.78Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M117.76,314.28V315h-3.38v-.72Zm-2.33-1.7h.83v6.94a1.37,1.37,0,0,0,.15.7.8.8,0,0,0,.4.35,1.27,1.27,0,0,0,.52.11l.28,0,.2-.05.18.75-.3.08a1.8,1.8,0,0,1-.43,0,2,2,0,0,1-.87-.2,1.71,1.71,0,0,1-.69-.59,1.68,1.68,0,0,1-.27-1Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M120.05,313.06a.59.59,0,0,1-.43-.17.54.54,0,0,1-.18-.42.54.54,0,0,1,.18-.41.6.6,0,0,1,.43-.18.6.6,0,0,1,.44.18.54.54,0,0,1,.18.41.54.54,0,0,1-.18.42A.6.6,0,0,1,120.05,313.06Zm-.42,8.31v-7.09h.83v7.09Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M125.39,321.52a2.87,2.87,0,0,1-1.62-.46,3.3,3.3,0,0,1-1.1-1.29,4.43,4.43,0,0,1-.4-1.91,4.48,4.48,0,0,1,.4-1.92,3.17,3.17,0,0,1,1.1-1.29,2.87,2.87,0,0,1,1.62-.46,2.83,2.83,0,0,1,1.62.46,3.19,3.19,0,0,1,1.11,1.29,4.48,4.48,0,0,1,.4,1.92,4.43,4.43,0,0,1-.4,1.91,3.3,3.3,0,0,1-1.1,1.29A2.88,2.88,0,0,1,125.39,321.52Zm0-.75a2,2,0,0,0,1.26-.4,2.34,2.34,0,0,0,.77-1.06,3.75,3.75,0,0,0,.27-1.45,3.87,3.87,0,0,0-.27-1.46,2.4,2.4,0,0,0-.77-1.06,2,2,0,0,0-1.26-.41,2,2,0,0,0-1.24.41,2.34,2.34,0,0,0-.78,1.06,3.87,3.87,0,0,0-.27,1.46,3.75,3.75,0,0,0,.27,1.45,2.34,2.34,0,0,0,.77,1.06A2,2,0,0,0,125.39,320.77Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M131.14,316.94v4.43h-.82v-7.09h.8v1.12h.07a2,2,0,0,1,.78-.88,2.43,2.43,0,0,1,1.31-.33,2.55,2.55,0,0,1,1.26.3,2.07,2.07,0,0,1,.84.87,3.07,3.07,0,0,1,.3,1.42v4.59h-.82v-4.54a1.93,1.93,0,0,0-.48-1.38,1.73,1.73,0,0,0-1.31-.51,2,2,0,0,0-1,.24,1.76,1.76,0,0,0-.69.69A2.25,2.25,0,0,0,131.14,316.94Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b1b2c73c-9e21-464e-bc57-29b085fa4b84"
      d="M153.6,313.74h-.08a4.1,4.1,0,0,0,0,8.2h.08a4.1,4.1,0,0,0,0-8.2Zm-.08-135.46v-7.06a10.92,10.92,0,0,1,10.92-10.93H191.5m-38,51.85V186.47m0,59.54V220.34m0,59.53V254.2m0,59.54V288.07m0,33.87v11a10.92,10.92,0,0,0,10.92,10.93h36.73c6,0,11.33,4.64,11.33,10.67v3"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b1b2c73c-9e21-464e-bc57-29b085fa4b84"
      d="M157.7,182.38a4.1,4.1,0,0,1-4.1,4.09h-.08a4.1,4.1,0,0,1,0-8.19h.08A4.1,4.1,0,0,1,157.7,182.38Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b1b2c73c-9e21-464e-bc57-29b085fa4b84"
      d="M157.7,216.24a4.11,4.11,0,0,1-4.1,4.1h-.08a4.1,4.1,0,0,1,0-8.2h.08A4.11,4.11,0,0,1,157.7,216.24Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b1b2c73c-9e21-464e-bc57-29b085fa4b84"
      d="M157.7,250.11a4.1,4.1,0,0,1-4.1,4.09h-.08a4.1,4.1,0,0,1,0-8.19h.08A4.1,4.1,0,0,1,157.7,250.11Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b1b2c73c-9e21-464e-bc57-29b085fa4b84"
      d="M157.7,284a4.1,4.1,0,0,1-4.1,4.1h-.08a4.1,4.1,0,0,1,0-8.2h.08A4.11,4.11,0,0,1,157.7,284Z"
      transform="translate(-5.07 -32.75)"
    />
    <g id="b41b54c8-7596-4aac-bc8c-9b5a5c6d5dbf" data-name="CSS Vert">
      <path
        class="fc9a5236-f453-4027-9306-71395085465e"
        d="M212.43,186l-16.09-4.58-1.87-21L192.75,141h39.6l-3.58,40.42L212.58,186Zm.14-9.66,9.37-2.67L224.09,150H201l.31,3.8H220l-.56,6H207.34l.35,3.71h11.43l-.77,7.35-5.8,1.61-.15-.05-5.73-1.57-.34-3.76h-3.78l.46,6.19Z"
        transform="translate(-5.07 -32.75)"
      />
      <path
        class="a6d67499-94fe-48e0-b2f0-0b01122138f8"
        d="M231.8,141.45,228.3,181l-15.79,4.47h0L196.81,181,195,160.29l-1.67-18.84h38.5m-19.3,12.9h6.93l-.47,5H206.79l.09,1,.35,3.75h11.33l-.67,6.46L212.56,172l-.06,0-5.36-1.47-.35-3.87H202l.53,7.07,10,3.1.06,0,9.85-2.8,2.23-24.47H200.47l.39,4.8H212.5m20.39-13.9H192.21l.09,1.09L194,160.38l1.84,20.69.06.68.67.19,15.69,4.47.13,0h.28l.14,0,15.79-4.47.67-.19.06-.68,3.5-39.53.09-1.09Zm-31.34,10.09h22l-2.06,22.7-8.91,2.53-9.08-2.83-.4-5.32h2.79l.26,3,.07.69.67.18,5.33,1.47h0l.29.1.3-.09,5.33-1.48.65-.18.07-.68.67-6.46.12-1.1H208.14l-.25-2.71h12l.09-.91.47-5,.1-1.09H201.78l-.23-2.8Z"
        transform="translate(-5.07 -32.75)"
      />
    </g>
    <path
      d="M292.46,70.65a1.69,1.69,0,0,0-.7-1.25,2.51,2.51,0,0,0-1.55-.47,2.79,2.79,0,0,0-1.16.23,1.91,1.91,0,0,0-.79.62,1.53,1.53,0,0,0-.28.91,1.29,1.29,0,0,0,.16.66,1.54,1.54,0,0,0,.44.48,2.75,2.75,0,0,0,.61.34,4.12,4.12,0,0,0,.67.24l1,.29a6.46,6.46,0,0,1,.9.32,3.75,3.75,0,0,1,.83.48,2.49,2.49,0,0,1,.61.72,2.12,2.12,0,0,1,.23,1,2.5,2.5,0,0,1-.4,1.37,2.63,2.63,0,0,1-1.12,1,4,4,0,0,1-1.76.36,4.13,4.13,0,0,1-1.68-.32,2.78,2.78,0,0,1-1.12-.89,2.49,2.49,0,0,1-.45-1.32h.88a1.71,1.71,0,0,0,.37,1,2.13,2.13,0,0,0,.84.59,3.28,3.28,0,0,0,1.16.2,3.15,3.15,0,0,0,1.26-.24,2.07,2.07,0,0,0,.86-.67,1.61,1.61,0,0,0,.32-1,1.28,1.28,0,0,0-.25-.81,2.13,2.13,0,0,0-.69-.54,6,6,0,0,0-1-.39l-1.13-.34a4.18,4.18,0,0,1-1.72-.92,2,2,0,0,1-.62-1.49,2.26,2.26,0,0,1,.41-1.34,2.71,2.71,0,0,1,1.11-.91,3.76,3.76,0,0,1,1.57-.32,3.51,3.51,0,0,1,1.56.32,2.79,2.79,0,0,1,1.08.89,2.39,2.39,0,0,1,.43,1.28Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M295.51,77.75V68.29H301v.78h-4.62v3.56h4.33v.77h-4.33V77h4.71v.78Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M311,73a5.9,5.9,0,0,1-.52,2.57A4.15,4.15,0,0,1,309,77.28a3.8,3.8,0,0,1-2.12.59,3.75,3.75,0,0,1-2.11-.59,4.07,4.07,0,0,1-1.45-1.69,6.61,6.61,0,0,1,0-5.14,4,4,0,0,1,1.45-1.69,4,4,0,0,1,4.23,0,4.08,4.08,0,0,1,1.45,1.69A5.87,5.87,0,0,1,311,73Zm-.84,0a5.17,5.17,0,0,0-.42-2.18,3.34,3.34,0,0,0-1.16-1.39,3,3,0,0,0-1.67-.48,3,3,0,0,0-1.66.48,3.27,3.27,0,0,0-1.16,1.39,5.72,5.72,0,0,0,0,4.35,3.21,3.21,0,0,0,1.16,1.39,3,3,0,0,0,1.66.48,3,3,0,0,0,1.67-.48,3.21,3.21,0,0,0,1.16-1.39A4.83,4.83,0,0,0,310.13,73Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M296,103.31h-.86a3.18,3.18,0,0,0-.35-.9,3.08,3.08,0,0,0-.63-.72,3.28,3.28,0,0,0-.86-.48,3.19,3.19,0,0,0-1-.17,3,3,0,0,0-1.63.47,3.2,3.2,0,0,0-1.18,1.37,5,5,0,0,0-.44,2.2,5.08,5.08,0,0,0,.44,2.22,3.26,3.26,0,0,0,1.18,1.37,3.06,3.06,0,0,0,1.63.46,3.19,3.19,0,0,0,1-.17,3,3,0,0,0,.86-.48,2.72,2.72,0,0,0,.63-.72,3.18,3.18,0,0,0,.35-.9H296a3.64,3.64,0,0,1-.43,1.21,3.65,3.65,0,0,1-1.92,1.64,4.14,4.14,0,0,1-1.39.23,3.81,3.81,0,0,1-2.12-.6,4,4,0,0,1-1.44-1.68,6.52,6.52,0,0,1,0-5.15,4,4,0,0,1,1.44-1.68,3.81,3.81,0,0,1,2.12-.6,4.14,4.14,0,0,1,1.39.23,3.65,3.65,0,0,1,1.92,1.64A3.64,3.64,0,0,1,296,103.31Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M299.8,110a2.7,2.7,0,0,1-1.17-.25,2,2,0,0,1-1.16-1.89,1.82,1.82,0,0,1,.2-.88,1.69,1.69,0,0,1,.56-.6,3.3,3.3,0,0,1,.87-.36,8,8,0,0,1,1.1-.21l1-.13a1.66,1.66,0,0,0,.62-.17.41.41,0,0,0,.22-.39v-.17a1.51,1.51,0,0,0-.43-1.14,1.71,1.71,0,0,0-1.24-.42,2.17,2.17,0,0,0-1.25.34,1.79,1.79,0,0,0-.67.79l-.78-.28a2.22,2.22,0,0,1,.66-.93,2.63,2.63,0,0,1,1-.5,3.91,3.91,0,0,1,1.07-.16,3.74,3.74,0,0,1,.85.11,2.29,2.29,0,0,1,.81.37,1.83,1.83,0,0,1,.62.72,2.69,2.69,0,0,1,.23,1.18v4.81h-.82v-1.12H302a2.18,2.18,0,0,1-.44.61,2.11,2.11,0,0,1-.73.48A2.49,2.49,0,0,1,299.8,110Zm.11-.75a2.19,2.19,0,0,0,1.14-.29,2,2,0,0,0,.74-.77,2.26,2.26,0,0,0,.26-1.07v-1a.82.82,0,0,1-.3.16l-.5.12-.56.09-.49.06a4.94,4.94,0,0,0-1,.23,1.5,1.5,0,0,0-.65.44,1.05,1.05,0,0,0-.23.7,1.19,1.19,0,0,0,.47,1A1.85,1.85,0,0,0,299.91,109.22Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M305.88,105.38v4.43h-.82v-7.09h.8v1.11h.07a2,2,0,0,1,.78-.87,2.43,2.43,0,0,1,1.31-.33,2.54,2.54,0,0,1,1.25.3,2,2,0,0,1,.85.87,3.07,3.07,0,0,1,.3,1.42v4.59h-.82v-4.54a1.89,1.89,0,0,0-.49-1.38,1.71,1.71,0,0,0-1.3-.51,2,2,0,0,0-1,.24,1.76,1.76,0,0,0-.69.69A2.14,2.14,0,0,0,305.88,105.38Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M317.82,102.72l-2.59,7.09h-.88l-2.59-7.09h.9l2.1,6h.07l2.1-6Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M321.18,110a2.64,2.64,0,0,1-1.17-.25,2,2,0,0,1-.84-.73,2.05,2.05,0,0,1-.32-1.16,1.82,1.82,0,0,1,.2-.88,1.69,1.69,0,0,1,.56-.6,3.3,3.3,0,0,1,.87-.36,7.69,7.69,0,0,1,1.1-.21l1-.13a1.66,1.66,0,0,0,.63-.17.41.41,0,0,0,.22-.39v-.17a1.51,1.51,0,0,0-.43-1.14,1.71,1.71,0,0,0-1.24-.42,2.17,2.17,0,0,0-1.25.34,1.88,1.88,0,0,0-.68.79l-.78-.28a2.33,2.33,0,0,1,.67-.93,2.63,2.63,0,0,1,.95-.5,3.91,3.91,0,0,1,1.07-.16,3.59,3.59,0,0,1,.84.11,2.26,2.26,0,0,1,.82.37,1.83,1.83,0,0,1,.62.72,2.69,2.69,0,0,1,.23,1.18v4.81h-.82v-1.12h-.05a2.18,2.18,0,0,1-.44.61,2.11,2.11,0,0,1-.73.48A2.49,2.49,0,0,1,321.18,110Zm.11-.75a2.19,2.19,0,0,0,1.14-.29,2,2,0,0,0,.74-.77,2.26,2.26,0,0,0,.26-1.07v-1a.8.8,0,0,1-.31.16l-.49.12-.56.09-.49.06a4.8,4.8,0,0,0-1,.23,1.5,1.5,0,0,0-.65.44,1.05,1.05,0,0,0-.23.7,1.21,1.21,0,0,0,.46,1A1.9,1.9,0,0,0,321.29,109.22Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M331.2,104.28l-.75.21a2,2,0,0,0-.32-.58,1.42,1.42,0,0,0-.52-.41,2,2,0,0,0-.81-.15,1.92,1.92,0,0,0-1.18.34,1,1,0,0,0-.46.88.94.94,0,0,0,.31.74,2.3,2.3,0,0,0,1,.45l1.07.27a2.69,2.69,0,0,1,1.34.68,1.7,1.7,0,0,1,.44,1.19A1.78,1.78,0,0,1,331,109a2.14,2.14,0,0,1-.93.73,3.25,3.25,0,0,1-1.37.27,3,3,0,0,1-1.71-.47,2.12,2.12,0,0,1-.87-1.35l.79-.2a1.65,1.65,0,0,0,.6,1,2,2,0,0,0,1.18.32,2.07,2.07,0,0,0,1.3-.37,1.09,1.09,0,0,0,.49-.92,1,1,0,0,0-.28-.72,1.78,1.78,0,0,0-.87-.43l-1.16-.28a2.69,2.69,0,0,1-1.37-.7,1.72,1.72,0,0,1-.44-1.19,1.75,1.75,0,0,1,.32-1,2.07,2.07,0,0,1,.88-.7,3,3,0,0,1,1.27-.26,2.49,2.49,0,0,1,1.56.45A2.38,2.38,0,0,1,331.2,104.28Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M293.49,134.79a1.75,1.75,0,0,0-.7-1.26,2.51,2.51,0,0,0-1.56-.46,2.82,2.82,0,0,0-1.16.22,1.88,1.88,0,0,0-.78.63,1.52,1.52,0,0,0-.29.91,1.29,1.29,0,0,0,.17.65,1.41,1.41,0,0,0,.43.48,3,3,0,0,0,.61.35c.22.09.45.17.68.24l1,.28a7.56,7.56,0,0,1,.91.32,3.63,3.63,0,0,1,.83.49,2.17,2.17,0,0,1,.6.71,2.05,2.05,0,0,1,.23,1,2.46,2.46,0,0,1-.39,1.36,2.75,2.75,0,0,1-1.13,1,4.56,4.56,0,0,1-3.43,0,2.63,2.63,0,0,1-1.12-.89,2.58,2.58,0,0,1-.46-1.31h.89a1.61,1.61,0,0,0,.37.94,1.91,1.91,0,0,0,.84.59,3.23,3.23,0,0,0,1.15.2,3.15,3.15,0,0,0,1.26-.24,2,2,0,0,0,.87-.66,1.66,1.66,0,0,0,.32-1,1.31,1.31,0,0,0-.25-.8,2,2,0,0,0-.7-.55,5.84,5.84,0,0,0-1-.39l-1.13-.33a4.23,4.23,0,0,1-1.73-.92,2,2,0,0,1-.61-1.5,2.22,2.22,0,0,1,.41-1.34,2.72,2.72,0,0,1,1.1-.9,3.66,3.66,0,0,1,1.58-.33,3.72,3.72,0,0,1,1.56.32,2.77,2.77,0,0,1,1.07.89,2.34,2.34,0,0,1,.43,1.29Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M299.23,142a3,3,0,0,1-2.84-1.75,4.42,4.42,0,0,1-.39-1.9,4.46,4.46,0,0,1,.39-1.91,3.29,3.29,0,0,1,1.1-1.31,2.82,2.82,0,0,1,1.61-.47,3.05,3.05,0,0,1,1.12.21,3,3,0,0,1,1,.64,3.21,3.21,0,0,1,.68,1.09,4.44,4.44,0,0,1,.25,1.54v.41h-5.54v-.73h4.69a2.77,2.77,0,0,0-.27-1.23,2.19,2.19,0,0,0-.76-.86,2,2,0,0,0-1.13-.32,2,2,0,0,0-1.18.36,2.47,2.47,0,0,0-.8.95,3,3,0,0,0-.3,1.29v.44a3.38,3.38,0,0,0,.3,1.47,2.22,2.22,0,0,0,.83,1,2.33,2.33,0,0,0,1.28.34,2.39,2.39,0,0,0,.88-.15,2,2,0,0,0,.64-.42,1.87,1.87,0,0,0,.4-.59l.78.26a2.31,2.31,0,0,1-.53.82,2.69,2.69,0,0,1-.91.61A3.33,3.33,0,0,1,299.23,142Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M303.91,141.88v-7.09h.8v1.09h.07a1.74,1.74,0,0,1,.71-.87,2.18,2.18,0,0,1,1.19-.31,2,2,0,0,1,1.21.34,2.05,2.05,0,0,1,.71.94h.06a2,2,0,0,1,.78-.94,2.4,2.4,0,0,1,1.32-.34,2,2,0,0,1,1.56.62,2.57,2.57,0,0,1,.57,1.81v4.75h-.82v-4.75a1.67,1.67,0,0,0-.43-1.26,1.53,1.53,0,0,0-1.13-.42,1.55,1.55,0,0,0-1.25.5,1.84,1.84,0,0,0-.44,1.26v4.67H308v-4.82a1.68,1.68,0,0,0-.4-1.17,1.46,1.46,0,0,0-1.13-.44,1.64,1.64,0,0,0-.88.24,1.59,1.59,0,0,0-.61.67,2.06,2.06,0,0,0-.23,1v4.54Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M317,142a2.67,2.67,0,0,1-1.17-.25,2,2,0,0,1-.84-.73,2.08,2.08,0,0,1-.32-1.16,1.86,1.86,0,0,1,.2-.89,1.66,1.66,0,0,1,.56-.59,3.3,3.3,0,0,1,.87-.36,8,8,0,0,1,1.1-.21l1-.13a1.84,1.84,0,0,0,.63-.18.39.39,0,0,0,.22-.38V137a1.51,1.51,0,0,0-.43-1.14,1.71,1.71,0,0,0-1.24-.42,2.1,2.1,0,0,0-1.25.34,1.79,1.79,0,0,0-.67.79l-.78-.28a2.22,2.22,0,0,1,.66-.93,2.63,2.63,0,0,1,.95-.5,3.47,3.47,0,0,1,1.91,0,2.41,2.41,0,0,1,.82.36,1.94,1.94,0,0,1,.62.73,2.68,2.68,0,0,1,.23,1.17v4.82h-.82v-1.12h-.05a2.18,2.18,0,0,1-.44.61,2.26,2.26,0,0,1-.73.48A2.49,2.49,0,0,1,317,142Zm.11-.75a2.19,2.19,0,0,0,1.14-.29,2,2,0,0,0,.74-.78,2.22,2.22,0,0,0,.26-1.06v-1a.81.81,0,0,1-.31.15,2.7,2.7,0,0,1-.49.12,5.43,5.43,0,0,1-.56.09l-.49.07a4.94,4.94,0,0,0-1,.23,1.48,1.48,0,0,0-.65.43,1.1,1.1,0,0,0-.23.71,1.19,1.19,0,0,0,.47,1A1.85,1.85,0,0,0,317.15,141.29Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M323.12,137.45v4.43h-.82v-7.09h.8v1.11h.07A2,2,0,0,1,324,135a2.43,2.43,0,0,1,1.31-.33,2.65,2.65,0,0,1,1.25.29,2,2,0,0,1,.85.88,3,3,0,0,1,.3,1.42v4.59h-.82v-4.54a1.86,1.86,0,0,0-.49-1.38,1.71,1.71,0,0,0-1.3-.51,2,2,0,0,0-1,.24,1.76,1.76,0,0,0-.69.69A2.14,2.14,0,0,0,323.12,137.45Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M332.62,134.79v.71h-3.38v-.71Zm-2.32-1.7h.82V140a1.24,1.24,0,0,0,.16.69.84.84,0,0,0,.39.36,1.34,1.34,0,0,0,.52.1,1.19,1.19,0,0,0,.28,0l.2,0,.18.74-.3.09a2.42,2.42,0,0,1-.43,0,2,2,0,0,1-.87-.2,1.69,1.69,0,0,1-.68-.59,1.71,1.71,0,0,1-.27-1Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M334.92,133.57a.61.61,0,0,1-.43-.17.56.56,0,0,1,0-.84.63.63,0,0,1,.86,0,.56.56,0,0,1,0,.84A.61.61,0,0,1,334.92,133.57Zm-.42,8.31v-7.09h.83v7.09Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M340.27,142a2.86,2.86,0,0,1-1.65-.48,3.16,3.16,0,0,1-1.09-1.3,4.36,4.36,0,0,1-.39-1.88,4.42,4.42,0,0,1,.39-1.9,3.29,3.29,0,0,1,1.1-1.31,3.08,3.08,0,0,1,2.92-.19,2.55,2.55,0,0,1,.95.77,2.41,2.41,0,0,1,.47,1.15h-.83a1.77,1.77,0,0,0-.62-1,1.81,1.81,0,0,0-1.25-.43,2,2,0,0,0-1.2.37,2.38,2.38,0,0,0-.81,1,3.57,3.57,0,0,0-.29,1.51,3.82,3.82,0,0,0,.28,1.52,2.37,2.37,0,0,0,.81,1,2,2,0,0,0,1.21.37,2,2,0,0,0,.86-.17,1.84,1.84,0,0,0,.66-.51,1.75,1.75,0,0,0,.36-.78H343a2.4,2.4,0,0,1-.46,1.14,2.46,2.46,0,0,1-.93.79A3,3,0,0,1,340.27,142Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M349.5,136.34l-.75.22a1.92,1.92,0,0,0-.32-.58,1.42,1.42,0,0,0-.52-.41,2,2,0,0,0-.81-.15,1.92,1.92,0,0,0-1.18.34,1,1,0,0,0-.46.88,1,1,0,0,0,.31.74,2.3,2.3,0,0,0,1,.45l1.07.26a2.74,2.74,0,0,1,1.34.69,1.69,1.69,0,0,1,.44,1.18,1.81,1.81,0,0,1-.33,1.07,2.22,2.22,0,0,1-.93.73A3.25,3.25,0,0,1,347,142a3,3,0,0,1-1.71-.47,2.09,2.09,0,0,1-.86-1.36l.78-.19a1.7,1.7,0,0,0,.6,1,2,2,0,0,0,1.18.32,2.07,2.07,0,0,0,1.3-.37,1.09,1.09,0,0,0,.49-.92,1,1,0,0,0-.28-.72,1.7,1.7,0,0,0-.87-.43l-1.16-.28a2.73,2.73,0,0,1-1.37-.7,1.82,1.82,0,0,1-.12-2.22,2,2,0,0,1,.88-.7,3,3,0,0,1,1.27-.26,2.55,2.55,0,0,1,1.56.44A2.46,2.46,0,0,1,349.5,136.34Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M288.37,174h-.91l3.44-9.46h.92l3.44,9.46h-.9l-3-8.34h-.06Zm.65-3.63h4.69v.78H289Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M299.65,174h-2.77v-9.46h2.94a4.45,4.45,0,0,1,2.31.57,3.61,3.61,0,0,1,1.48,1.61,6.52,6.52,0,0,1,0,5.08,3.75,3.75,0,0,1-1.52,1.63A4.85,4.85,0,0,1,299.65,174Zm-1.9-.78h1.85a4,4,0,0,0,2-.48,3.06,3.06,0,0,0,1.24-1.37,5.07,5.07,0,0,0,.41-2.12,5.19,5.19,0,0,0-.41-2.11,2.89,2.89,0,0,0-1.18-1.35,3.61,3.61,0,0,0-1.93-.47h-2Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M305.78,174h-.91l3.44-9.46h.93l3.44,9.46h-.91l-3-8.34h-.07Zm.65-3.63h4.69v.78h-4.69Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M318,174h-.91l3.44-9.46h.92l3.44,9.46H324l-3-8.34h-.07Zm.65-3.63h4.69v.78h-4.69Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M329.08,174.09a2.85,2.85,0,0,1-1.65-.47,3.09,3.09,0,0,1-1.09-1.3,4.77,4.77,0,0,1,0-3.78,3.23,3.23,0,0,1,1.1-1.31,2.89,2.89,0,0,1,1.63-.47,3,3,0,0,1,1.29.27,2.65,2.65,0,0,1,.95.77,2.44,2.44,0,0,1,.47,1.16H331a1.83,1.83,0,0,0-.62-1,1.85,1.85,0,0,0-1.25-.42,2.07,2.07,0,0,0-1.2.36,2.51,2.51,0,0,0-.81,1,3.62,3.62,0,0,0-.29,1.52,3.82,3.82,0,0,0,.28,1.52,2.43,2.43,0,0,0,.81,1,2.16,2.16,0,0,0,2.07.19,1.71,1.71,0,0,0,.66-.5,1.75,1.75,0,0,0,.36-.78h.83a2.4,2.4,0,0,1-.46,1.14,2.55,2.55,0,0,1-.93.79A2.94,2.94,0,0,1,329.08,174.09Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M336.32,174.09a2.86,2.86,0,0,1-1.66-.47,3.16,3.16,0,0,1-1.09-1.3,4.37,4.37,0,0,1-.39-1.89,4.23,4.23,0,0,1,.4-1.89,3.21,3.21,0,0,1,1.09-1.31,2.91,2.91,0,0,1,1.63-.47,3,3,0,0,1,1.29.27,2.57,2.57,0,0,1,1,.77A2.45,2.45,0,0,1,339,169h-.83a2,2,0,0,0-.62-1,1.87,1.87,0,0,0-1.25-.42,2,2,0,0,0-1.2.36,2.53,2.53,0,0,0-.82,1,3.78,3.78,0,0,0-.29,1.52,3.65,3.65,0,0,0,.29,1.52,2.41,2.41,0,0,0,.8,1,2.18,2.18,0,0,0,2.08.19,1.6,1.6,0,0,0,.65-.5,1.75,1.75,0,0,0,.36-.78H339a2.49,2.49,0,0,1-.45,1.14,2.58,2.58,0,0,1-.94.79A2.9,2.9,0,0,1,336.32,174.09Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M343.65,174.09a3.11,3.11,0,0,1-1.72-.46,3.07,3.07,0,0,1-1.12-1.29,4.82,4.82,0,0,1,0-3.8,3.15,3.15,0,0,1,1.1-1.31,2.82,2.82,0,0,1,1.61-.47,3.05,3.05,0,0,1,1.12.21,2.84,2.84,0,0,1,1,.64,3,3,0,0,1,.67,1.09,4.21,4.21,0,0,1,.25,1.54v.41H341v-.73h4.7a2.77,2.77,0,0,0-.27-1.23,2.19,2.19,0,0,0-.76-.86,2.13,2.13,0,0,0-2.31,0,2.4,2.4,0,0,0-.8.95,2.91,2.91,0,0,0-.3,1.29v.43a3.4,3.4,0,0,0,.3,1.48,2.28,2.28,0,0,0,.83,1,2.33,2.33,0,0,0,1.28.34,2.19,2.19,0,0,0,.88-.16,1.7,1.7,0,0,0,.64-.42,1.73,1.73,0,0,0,.4-.58l.78.25a2.39,2.39,0,0,1-.53.83,2.85,2.85,0,0,1-.91.61A3.33,3.33,0,0,1,343.65,174.09Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M353.09,168.41l-.75.21a2,2,0,0,0-.31-.57,1.43,1.43,0,0,0-.53-.41,1.8,1.8,0,0,0-.81-.16,1.92,1.92,0,0,0-1.18.35,1,1,0,0,0-.46.88.93.93,0,0,0,.32.74,2.25,2.25,0,0,0,1,.45l1.07.26a2.74,2.74,0,0,1,1.34.69,1.63,1.63,0,0,1,.44,1.18,1.81,1.81,0,0,1-.33,1.07,2.25,2.25,0,0,1-.92.73,3.33,3.33,0,0,1-1.38.26,3,3,0,0,1-1.71-.46,2.12,2.12,0,0,1-.86-1.36l.79-.19a1.55,1.55,0,0,0,.6.95,1.89,1.89,0,0,0,1.17.33,2.19,2.19,0,0,0,1.31-.37,1.13,1.13,0,0,0,.49-.92,1,1,0,0,0-.29-.73,1.87,1.87,0,0,0-.87-.43l-1.16-.27a2.66,2.66,0,0,1-1.36-.71,1.65,1.65,0,0,1-.45-1.19,1.75,1.75,0,0,1,.32-1,2.21,2.21,0,0,1,.88-.7,3,3,0,0,1,1.27-.25,2.55,2.55,0,0,1,1.56.44A2.42,2.42,0,0,1,353.09,168.41Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M359.76,168.41l-.75.21a2,2,0,0,0-.32-.57,1.29,1.29,0,0,0-.53-.41,1.73,1.73,0,0,0-.8-.16,1.9,1.9,0,0,0-1.18.35,1,1,0,0,0-.47.88,1,1,0,0,0,.32.74,2.3,2.3,0,0,0,1,.45l1.07.26a2.78,2.78,0,0,1,1.34.69,1.67,1.67,0,0,1,.44,1.18,1.81,1.81,0,0,1-.33,1.07,2.22,2.22,0,0,1-.93.73,3.25,3.25,0,0,1-1.37.26,3,3,0,0,1-1.71-.46,2.17,2.17,0,0,1-.87-1.36l.79-.19a1.55,1.55,0,0,0,.6.95,1.94,1.94,0,0,0,1.18.33,2.13,2.13,0,0,0,1.3-.37,1.11,1.11,0,0,0,.49-.92,1,1,0,0,0-.28-.73,1.92,1.92,0,0,0-.87-.43l-1.16-.27a2.69,2.69,0,0,1-1.37-.71,1.68,1.68,0,0,1-.44-1.19,1.75,1.75,0,0,1,.32-1,2.18,2.18,0,0,1,.87-.7,3.08,3.08,0,0,1,1.28-.25,2.55,2.55,0,0,1,1.56.44A2.42,2.42,0,0,1,359.76,168.41Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M362.09,165.64a.58.58,0,0,1-.43-.18.54.54,0,0,1-.18-.41.54.54,0,0,1,.18-.42.57.57,0,0,1,.43-.17.6.6,0,0,1,.44.17.54.54,0,0,1,.18.42.54.54,0,0,1-.18.41A.6.6,0,0,1,362.09,165.64Zm-.42,8.31v-7.09h.83V174Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M364.85,174v-9.46h.82v3.72h.08a3,3,0,0,1,.4-.66,2.11,2.11,0,0,1,.68-.56,2.21,2.21,0,0,1,1.08-.23,2.59,2.59,0,0,1,1.53.46,2.93,2.93,0,0,1,1,1.28,4.6,4.6,0,0,1,.37,1.92,4.77,4.77,0,0,1-.36,1.92,3,3,0,0,1-1,1.29,2.58,2.58,0,0,1-1.52.46,2.21,2.21,0,0,1-1.08-.23,2.09,2.09,0,0,1-.7-.57,2.91,2.91,0,0,1-.4-.66h-.1V174Zm.81-3.54a4,4,0,0,0,.26,1.52,2.42,2.42,0,0,0,.73,1,2,2,0,0,0,2.37,0,2.39,2.39,0,0,0,.74-1,4.16,4.16,0,0,0,.26-1.49,4.29,4.29,0,0,0-.25-1.48,2.43,2.43,0,0,0-.75-1,2.06,2.06,0,0,0-2.37,0,2.37,2.37,0,0,0-.74,1A4.16,4.16,0,0,0,365.66,170.41Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M373.08,165.64a.58.58,0,0,1-.43-.18.52.52,0,0,1-.19-.41.52.52,0,0,1,.19-.42.63.63,0,0,1,.86,0,.52.52,0,0,1,.19.42.52.52,0,0,1-.19.41A.58.58,0,0,1,373.08,165.64Zm-.42,8.31v-7.09h.82V174Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M376.48,164.49V174h-.82v-9.46Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M379.08,165.64a.6.6,0,0,1-.43-.18.54.54,0,0,1-.18-.41.54.54,0,0,1,.18-.42.59.59,0,0,1,.43-.17.6.6,0,0,1,.44.17.54.54,0,0,1,.18.42.54.54,0,0,1-.18.41A.6.6,0,0,1,379.08,165.64Zm-.42,8.31v-7.09h.83V174Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M384.46,166.86v.71h-3.38v-.71Zm-2.33-1.7H383v6.94a1.36,1.36,0,0,0,.15.69.91.91,0,0,0,.4.36,1.5,1.5,0,0,0,.52.1l.28,0,.2,0,.18.75a1.6,1.6,0,0,1-.3.08,1.74,1.74,0,0,1-.43,0,1.91,1.91,0,0,1-.87-.2,1.63,1.63,0,0,1-.69-.59,1.66,1.66,0,0,1-.27-1Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M386.9,176.6a1.71,1.71,0,0,1-.46,0,1.45,1.45,0,0,1-.33-.1l.22-.73a1.43,1.43,0,0,0,1.06,0,1.64,1.64,0,0,0,.72-1l.26-.72-2.61-7.16h.89l2.1,6h.07l2.1-6h.9l-3,8.2a3.25,3.25,0,0,1-.47.85,1.81,1.81,0,0,1-.64.51A1.92,1.92,0,0,1,386.9,176.6Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M292.67,198.92a1.75,1.75,0,0,0-.7-1.25,2.52,2.52,0,0,0-1.56-.47,2.82,2.82,0,0,0-1.16.23,1.94,1.94,0,0,0-.78.62,1.54,1.54,0,0,0-.29.91,1.3,1.3,0,0,0,.17.66,1.62,1.62,0,0,0,.43.48,3,3,0,0,0,.61.34,4.23,4.23,0,0,0,.68.24l1,.29a6.15,6.15,0,0,1,.91.32,4,4,0,0,1,.83.48,2.31,2.31,0,0,1,.6.72,2,2,0,0,1,.23,1,2.43,2.43,0,0,1-.39,1.36,2.61,2.61,0,0,1-1.13,1,4.44,4.44,0,0,1-3.43,0,2.69,2.69,0,0,1-1.12-.88,2.58,2.58,0,0,1-.46-1.32H288a1.64,1.64,0,0,0,.37.95,2.16,2.16,0,0,0,.84.59,3.23,3.23,0,0,0,1.15.2,3.15,3.15,0,0,0,1.26-.24,2,2,0,0,0,.87-.67,1.61,1.61,0,0,0,.32-1,1.35,1.35,0,0,0-.25-.81,2,2,0,0,0-.7-.54,6.72,6.72,0,0,0-1-.4l-1.13-.33a4.23,4.23,0,0,1-1.73-.92,2,2,0,0,1-.61-1.49,2.2,2.2,0,0,1,.41-1.34,2.71,2.71,0,0,1,1.11-.91,3.61,3.61,0,0,1,1.57-.32,3.57,3.57,0,0,1,1.56.32,2.7,2.7,0,0,1,1.08.89,2.35,2.35,0,0,1,.42,1.28Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M295.73,196.56l2.95,8.33h.09l3-8.33h.9L299.19,206h-.93l-3.44-9.45Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M310.34,199.51a3.26,3.26,0,0,0-.38-.88,2.69,2.69,0,0,0-1.46-1.21,3,3,0,0,0-1.06-.18,2.91,2.91,0,0,0-1.65.48,3.28,3.28,0,0,0-1.16,1.39,5.14,5.14,0,0,0-.42,2.18,5,5,0,0,0,.43,2.17,3.27,3.27,0,0,0,1.16,1.39,3.07,3.07,0,0,0,1.7.48,3,3,0,0,0,1.54-.39,2.68,2.68,0,0,0,1-1.11,3.54,3.54,0,0,0,.36-1.71l.28.09h-3v-.78h3.59v.78a4.19,4.19,0,0,1-.49,2.09,3.37,3.37,0,0,1-1.34,1.36,4,4,0,0,1-5.57-1.8,6,6,0,0,1-.51-2.57,6.59,6.59,0,0,1,.29-2,4.51,4.51,0,0,1,.84-1.53,3.63,3.63,0,0,1,1.29-1,3.82,3.82,0,0,1,1.64-.34,4,4,0,0,1,1.42.24,3.66,3.66,0,0,1,1.13.68,3.72,3.72,0,0,1,1.24,2.16Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b10cad2f-5bdc-4c3a-8d06-ea6c5ec54f4b"
      d="M273.07,205.64v7.26a10.92,10.92,0,0,1-10.92,10.93H223.42a10.92,10.92,0,0,0-10.92,10.92v2.75m60.57-40.06V173.37m0-8.19V141.13m0-8.2V108.88m0-8.19v-24M233.5,51.29h28.65a10.92,10.92,0,0,1,10.92,10.93v6.22"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b10cad2f-5bdc-4c3a-8d06-ea6c5ec54f4b"
      d="M277.09,201.54a4.11,4.11,0,0,1-4,4.1H273a4.1,4.1,0,0,1,0-8.2h.08A4.1,4.1,0,0,1,277.09,201.54Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b10cad2f-5bdc-4c3a-8d06-ea6c5ec54f4b"
      d="M277.09,169.28a4.1,4.1,0,0,1-4,4.09H273a4.1,4.1,0,1,1,0-8.19h.08A4.1,4.1,0,0,1,277.09,169.28Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b10cad2f-5bdc-4c3a-8d06-ea6c5ec54f4b"
      d="M277.09,137a4.11,4.11,0,0,1-4,4.1H273a4.1,4.1,0,0,1,0-8.2h.08A4.11,4.11,0,0,1,277.09,137Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b10cad2f-5bdc-4c3a-8d06-ea6c5ec54f4b"
      d="M277.09,104.79a4.1,4.1,0,0,1-4,4.09H273a4.1,4.1,0,1,1,0-8.19h.08A4.1,4.1,0,0,1,277.09,104.79Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b10cad2f-5bdc-4c3a-8d06-ea6c5ec54f4b"
      d="M277.09,72.54a4.1,4.1,0,0,1-4,4.1H273a4.1,4.1,0,0,1,0-8.2h.08A4.11,4.11,0,0,1,277.09,72.54Z"
      transform="translate(-5.07 -32.75)"
    />
    <g id="abd12fd2-4770-4c99-aae0-4bc8fca4528d" data-name="HTML">
      <path
        class="e9e8cfc3-cd04-4e93-9678-b77895814d9f"
        d="M193,32.75l3.51,39.53,15.7,4.47.26-.07L228,72.28l1.86-21,1.64-18.54Zm30.61,13.12h-18l.41,4.86h17.14l-.05.56-1.28,14-9.39,2.52-.22.06-9.69-2.59L202,57.84h4.66l.34,3.77,5.28,1.45.22-.06,5-1.38.58-6.12H201.75L200.5,41.19h23.55Z"
        transform="translate(-5.07 -32.75)"
      />
    </g>
    <g id="efa32aa9-70f5-4f82-ba68-97d53c262704" data-name="Adobe">
      <path
        class="a7d46a36-2de6-43d1-8e6f-1683debb0171"
        d="M214.09,408.29l-1.59-3.78-.42-1-2,4.78-4.08,9.6h6.51l0,.05,2.64,6.68H221Zm-20.9-17.09v33.42L200,408.29l7.14-17.09Zm23.81,0L231,424.62V391.2Z"
        transform="translate(-5.07 -32.75)"
      />
    </g>
    <path
      class="b10cad2f-5bdc-4c3a-8d06-ea6c5ec54f4b"
      d="M256.5,425.5m16.57-116.06v-7.22a10.92,10.92,0,0,0-10.92-10.93H234.5m38.57,59.82V317.64m0,75.13V359.31m0,75.13V401M212.5,474.5v-2.75a10.92,10.92,0,0,1,10.92-10.92h38.73a10.92,10.92,0,0,0,10.92-10.93v-7.26m0-8.2H273a4.1,4.1,0,0,0,0,8.2h.08a4.1,4.1,0,0,0,0-8.2Zm0-41.67H273a4.1,4.1,0,0,0,0,8.2h.08a4.1,4.1,0,0,0,0-8.2Zm0-41.66H273a4.1,4.1,0,0,0,0,8.2h.08a4.1,4.1,0,0,0,0-8.2Zm0-41.67H273a4.1,4.1,0,0,0,0,8.2h.08a4.1,4.1,0,0,0,0-8.2Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M319.53,432.85h1l3.43,8.23H324l3.43-8.23h1v9.45h-.81v-7.63h-.08l-3.19,7.63h-.78l-3.19-7.63h-.07v7.63h-.82Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M333.57,442.45a2.91,2.91,0,0,1-1.63-.47,3.24,3.24,0,0,1-1.1-1.29,4.39,4.39,0,0,1-.39-1.9,4.45,4.45,0,0,1,.39-1.92,3.24,3.24,0,0,1,1.1-1.29,2.91,2.91,0,0,1,1.63-.47,2.88,2.88,0,0,1,1.62.47,3.17,3.17,0,0,1,1.1,1.29,4.45,4.45,0,0,1,.4,1.92,4.25,4.25,0,0,1-.4,1.9,3.09,3.09,0,0,1-1.1,1.29A2.88,2.88,0,0,1,333.57,442.45Zm0-.76a2,2,0,0,0,1.25-.39,2.56,2.56,0,0,0,.78-1.06,4,4,0,0,0,.26-1.45,4,4,0,0,0-.26-1.46,2.53,2.53,0,0,0-.78-1.07,2.15,2.15,0,0,0-2.5,0,2.53,2.53,0,0,0-.78,1.07,3.83,3.83,0,0,0-.27,1.46,3.78,3.78,0,0,0,.27,1.45,2.47,2.47,0,0,0,.78,1.06A2,2,0,0,0,333.57,441.69Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M339.31,437.87v4.43h-.82v-7.09h.8v1.11h.08a2,2,0,0,1,.77-.87,2.45,2.45,0,0,1,1.31-.33,2.66,2.66,0,0,1,1.26.29,2,2,0,0,1,.84.88,2.92,2.92,0,0,1,.31,1.42v4.59H343v-4.54a1.94,1.94,0,0,0-.49-1.38,1.73,1.73,0,0,0-1.31-.51,2,2,0,0,0-1,.24,1.67,1.67,0,0,0-.68.69A2.15,2.15,0,0,0,339.31,437.87Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M348.73,445.11a4.09,4.09,0,0,1-1.27-.19,3,3,0,0,1-.92-.51,2.49,2.49,0,0,1-.58-.73l.68-.44a2.38,2.38,0,0,0,.4.53,2.21,2.21,0,0,0,.67.43,2.79,2.79,0,0,0,1,.17,2.34,2.34,0,0,0,1.52-.47,1.78,1.78,0,0,0,.58-1.47v-1.59h-.07a2.87,2.87,0,0,1-.41.64,2.12,2.12,0,0,1-.7.53,2.32,2.32,0,0,1-1.07.22,2.74,2.74,0,0,1-1.51-.42,2.86,2.86,0,0,1-1-1.21,4.26,4.26,0,0,1-.38-1.86,4.54,4.54,0,0,1,.37-1.9,3,3,0,0,1,1-1.27,2.59,2.59,0,0,1,1.53-.46,2.28,2.28,0,0,1,1.08.23,2,2,0,0,1,.69.57,3.16,3.16,0,0,1,.41.65h.09v-1.35h.8v7.27a2.72,2.72,0,0,1-.38,1.49,2.4,2.4,0,0,1-1,.86A3.93,3.93,0,0,1,348.73,445.11Zm-.05-3.64a2,2,0,0,0,1.91-1.28,3.83,3.83,0,0,0,.26-1.47,4,4,0,0,0-.26-1.47,2.35,2.35,0,0,0-.73-1,2.08,2.08,0,0,0-2.38,0,2.3,2.3,0,0,0-.74,1,4,4,0,0,0-.25,1.45,3.81,3.81,0,0,0,.25,1.44,2.13,2.13,0,0,0,.75,1A1.93,1.93,0,0,0,348.68,441.47Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M356.59,442.45A2.88,2.88,0,0,1,355,442a3.09,3.09,0,0,1-1.1-1.29,4.39,4.39,0,0,1-.4-1.9,4.45,4.45,0,0,1,.4-1.92,3.09,3.09,0,0,1,1.1-1.29,2.88,2.88,0,0,1,1.62-.47,2.83,2.83,0,0,1,1.62.47,3.19,3.19,0,0,1,1.11,1.29,4.45,4.45,0,0,1,.39,1.92,4.39,4.39,0,0,1-.39,1.9,3.17,3.17,0,0,1-1.1,1.29A2.89,2.89,0,0,1,356.59,442.45Zm0-.76a2,2,0,0,0,1.25-.39,2.39,2.39,0,0,0,.78-1.06,3.78,3.78,0,0,0,.27-1.45,3.83,3.83,0,0,0-.27-1.46,2.37,2.37,0,0,0-.78-1.07,1.93,1.93,0,0,0-1.25-.4,2,2,0,0,0-1.25.4,2.53,2.53,0,0,0-.78,1.07,4,4,0,0,0-.26,1.46,4,4,0,0,0,.26,1.45,2.56,2.56,0,0,0,.78,1.06A2,2,0,0,0,356.59,441.69Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M364.46,442.3h-2.77v-9.45h2.94a4.44,4.44,0,0,1,2.31.56,3.67,3.67,0,0,1,1.48,1.61,5.8,5.8,0,0,1,.51,2.53,5.86,5.86,0,0,1-.52,2.55,3.71,3.71,0,0,1-1.53,1.63A4.81,4.81,0,0,1,364.46,442.3Zm-1.91-.78h1.86a4,4,0,0,0,2-.48,2.93,2.93,0,0,0,1.23-1.37,4.92,4.92,0,0,0,.41-2.12,5,5,0,0,0-.4-2.11,3,3,0,0,0-1.18-1.35,3.73,3.73,0,0,0-1.93-.47h-2Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M371,442.3v-9.45h3.19a3.28,3.28,0,0,1,1.54.32,2.17,2.17,0,0,1,.92.88,2.54,2.54,0,0,1,.31,1.27,2.39,2.39,0,0,1-.2,1,1.74,1.74,0,0,1-.54.68,2.15,2.15,0,0,1-.76.37v.08a1.85,1.85,0,0,1,.89.32,2.19,2.19,0,0,1,.7.8,2.55,2.55,0,0,1,.28,1.24,2.59,2.59,0,0,1-.32,1.28,2.17,2.17,0,0,1-1,.88,3.82,3.82,0,0,1-1.67.32Zm.86-5.18h2.4a1.92,1.92,0,0,0,1-.24,1.67,1.67,0,0,0,.66-.63,1.78,1.78,0,0,0,.23-.93,1.63,1.63,0,0,0-.49-1.23,2,2,0,0,0-1.45-.47h-2.33Zm0,4.4h2.5A2.34,2.34,0,0,0,376,441a1.58,1.58,0,0,0,.52-1.22,1.82,1.82,0,0,0-.26-1,1.89,1.89,0,0,0-.73-.7,2.33,2.33,0,0,0-1.11-.26h-2.54Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M385.45,442.43a3.25,3.25,0,0,1-1.53-.34,2.52,2.52,0,0,1-1-.93,2.47,2.47,0,0,1-.36-1.32,2.07,2.07,0,0,1,.22-1,3,3,0,0,1,.64-.82,9.18,9.18,0,0,1,1-.81l1.37-1a3.19,3.19,0,0,0,.75-.69,1.35,1.35,0,0,0,.26-.83,1.25,1.25,0,0,0-1.29-1.23,1.25,1.25,0,0,0-.71.2,1.5,1.5,0,0,0-.49.51,1.31,1.31,0,0,0-.18.66,1.39,1.39,0,0,0,.16.65,4,4,0,0,0,.45.69l.63.78,4.32,5.34h-1l-3.76-4.62-.85-1a5.16,5.16,0,0,1-.58-.89,2,2,0,0,1-.21-.92,2,2,0,0,1,1.06-1.82,2.33,2.33,0,0,1,1.13-.27,2.29,2.29,0,0,1,1.14.27,2,2,0,0,1,.75.71,1.83,1.83,0,0,1,.27,1,2,2,0,0,1-.33,1.13,4.17,4.17,0,0,1-1,1l-1.72,1.28a3.84,3.84,0,0,0-1,1,1.49,1.49,0,0,0,0,1.69,1.91,1.91,0,0,0,.71.67,2.16,2.16,0,0,0,1.06.26,2.64,2.64,0,0,0,1.11-.24,3,3,0,0,0,.94-.66,3.55,3.55,0,0,0,.66-1,3.92,3.92,0,0,0,.29-1.35h.8a4.26,4.26,0,0,1-.2,1.29,3.92,3.92,0,0,1-.43.87c-.16.23-.3.41-.42.55l-.1.14-.11.14a3.07,3.07,0,0,1-1.14.77A4,4,0,0,1,385.45,442.43Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M394.81,432.85h1l3.43,8.23h.08l3.43-8.23h1v9.45h-.81v-7.63h-.08l-3.19,7.63h-.78l-3.19-7.63h-.07v7.63h-.82Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M408.85,442.45a2.89,2.89,0,0,1-1.63-.47,3.17,3.17,0,0,1-1.1-1.29,4.39,4.39,0,0,1-.39-1.9,4.45,4.45,0,0,1,.39-1.92,3.17,3.17,0,0,1,1.1-1.29,3,3,0,0,1,3.25,0,3.24,3.24,0,0,1,1.1,1.29,4.45,4.45,0,0,1,.4,1.92,4.39,4.39,0,0,1-.4,1.9,3.09,3.09,0,0,1-1.1,1.29A2.88,2.88,0,0,1,408.85,442.45Zm0-.76a2,2,0,0,0,1.25-.39,2.56,2.56,0,0,0,.78-1.06,4,4,0,0,0,.26-1.45,4,4,0,0,0-.26-1.46,2.53,2.53,0,0,0-.78-1.07,2.15,2.15,0,0,0-2.5,0,2.45,2.45,0,0,0-.78,1.07,3.83,3.83,0,0,0-.27,1.46,3.78,3.78,0,0,0,.27,1.45,2.47,2.47,0,0,0,.78,1.06A2,2,0,0,0,408.85,441.69Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M414.59,437.87v4.43h-.82v-7.09h.8v1.11h.07a2,2,0,0,1,.78-.87,2.45,2.45,0,0,1,1.31-.33,2.66,2.66,0,0,1,1.26.29,2,2,0,0,1,.84.88,2.92,2.92,0,0,1,.31,1.42v4.59h-.82v-4.54a1.94,1.94,0,0,0-.49-1.38,1.73,1.73,0,0,0-1.31-.51,2,2,0,0,0-1,.24,1.67,1.67,0,0,0-.68.69A2.15,2.15,0,0,0,414.59,437.87Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M424,445.11a4.09,4.09,0,0,1-1.27-.19,3,3,0,0,1-.92-.51,2.49,2.49,0,0,1-.58-.73l.68-.44a2.38,2.38,0,0,0,.4.53,2.34,2.34,0,0,0,.67.43,2.79,2.79,0,0,0,1,.17,2.34,2.34,0,0,0,1.52-.47,1.78,1.78,0,0,0,.58-1.47v-1.59H426a2.87,2.87,0,0,1-.41.64,2.12,2.12,0,0,1-.7.53,2.32,2.32,0,0,1-1.07.22,2.74,2.74,0,0,1-1.51-.42,2.86,2.86,0,0,1-1-1.21,4.26,4.26,0,0,1-.38-1.86,4.54,4.54,0,0,1,.37-1.9,3,3,0,0,1,1-1.27,2.59,2.59,0,0,1,1.53-.46,2.28,2.28,0,0,1,1.08.23,2,2,0,0,1,.69.57,3.16,3.16,0,0,1,.41.65h.09v-1.35h.8v7.27a2.72,2.72,0,0,1-.38,1.49,2.4,2.4,0,0,1-1.05.86A3.93,3.93,0,0,1,424,445.11Zm-.05-3.64a2,2,0,0,0,1.91-1.28,3.83,3.83,0,0,0,.26-1.47,4,4,0,0,0-.26-1.47,2.35,2.35,0,0,0-.73-1,2.08,2.08,0,0,0-2.38,0,2.3,2.3,0,0,0-.74,1,4,4,0,0,0-.25,1.45,3.81,3.81,0,0,0,.25,1.44,2.13,2.13,0,0,0,.75,1A1.93,1.93,0,0,0,424,441.47Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M431.87,442.45a2.88,2.88,0,0,1-1.62-.47,3.17,3.17,0,0,1-1.1-1.29,4.39,4.39,0,0,1-.4-1.9,4.45,4.45,0,0,1,.4-1.92,3.17,3.17,0,0,1,1.1-1.29,2.88,2.88,0,0,1,1.62-.47,2.83,2.83,0,0,1,1.62.47,3.19,3.19,0,0,1,1.11,1.29,4.45,4.45,0,0,1,.4,1.92,4.39,4.39,0,0,1-.4,1.9,3.17,3.17,0,0,1-1.1,1.29A2.89,2.89,0,0,1,431.87,442.45Zm0-.76a2,2,0,0,0,1.25-.39,2.39,2.39,0,0,0,.78-1.06,3.78,3.78,0,0,0,.27-1.45,3.83,3.83,0,0,0-.27-1.46,2.37,2.37,0,0,0-.78-1.07,1.93,1.93,0,0,0-1.25-.4,2,2,0,0,0-1.25.4,2.53,2.53,0,0,0-.78,1.07,4,4,0,0,0-.26,1.46,4,4,0,0,0,.26,1.45,2.56,2.56,0,0,0,.78,1.06A2,2,0,0,0,431.87,441.69Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M439.56,442.45a2.89,2.89,0,0,1-1.63-.47,3.17,3.17,0,0,1-1.1-1.29,4.39,4.39,0,0,1-.4-1.9,4.45,4.45,0,0,1,.4-1.92,3.17,3.17,0,0,1,1.1-1.29,3,3,0,0,1,3.25,0,3.24,3.24,0,0,1,1.1,1.29,4.45,4.45,0,0,1,.4,1.92,4.39,4.39,0,0,1-.4,1.9,3.17,3.17,0,0,1-1.1,1.29A2.88,2.88,0,0,1,439.56,442.45Zm0-.76a2,2,0,0,0,1.25-.39,2.45,2.45,0,0,0,.77-1.06,3.78,3.78,0,0,0,.27-1.45,3.83,3.83,0,0,0-.27-1.46,2.43,2.43,0,0,0-.77-1.07,2.15,2.15,0,0,0-2.5,0,2.37,2.37,0,0,0-.78,1.07,3.83,3.83,0,0,0-.27,1.46,3.78,3.78,0,0,0,.27,1.45,2.45,2.45,0,0,0,.77,1.06A2,2,0,0,0,439.56,441.69Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M449.25,436.76l-.76.22a1.9,1.9,0,0,0-.31-.58,1.45,1.45,0,0,0-.53-.41,2.24,2.24,0,0,0-2,.19,1.07,1.07,0,0,0-.46.88,1,1,0,0,0,.32.74,2.25,2.25,0,0,0,1,.45l1.08.26a2.75,2.75,0,0,1,1.33.69,1.65,1.65,0,0,1,.45,1.18,1.75,1.75,0,0,1-.34,1.07,2.17,2.17,0,0,1-.92.73,3.28,3.28,0,0,1-1.37.27A3,3,0,0,1,445,442a2.13,2.13,0,0,1-.86-1.36l.79-.19a1.6,1.6,0,0,0,.6,1,2,2,0,0,0,1.17.32,2.1,2.1,0,0,0,1.31-.37,1.12,1.12,0,0,0,.49-.92,1,1,0,0,0-.29-.72,1.7,1.7,0,0,0-.87-.43l-1.16-.28a2.65,2.65,0,0,1-1.36-.7,1.67,1.67,0,0,1-.44-1.19,1.72,1.72,0,0,1,.31-1,2.2,2.2,0,0,1,.88-.7,3,3,0,0,1,1.27-.26,2.31,2.31,0,0,1,2.41,1.65Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M454,442.45a3.09,3.09,0,0,1-1.71-.47,3,3,0,0,1-1.12-1.28,4.28,4.28,0,0,1-.4-1.9,4.32,4.32,0,0,1,.4-1.91,3.27,3.27,0,0,1,1.09-1.31,2.88,2.88,0,0,1,1.62-.47,3,3,0,0,1,1.11.21,2.79,2.79,0,0,1,1,.64,3.07,3.07,0,0,1,.68,1.09,4.21,4.21,0,0,1,.25,1.54V439h-5.54v-.73h4.7a2.77,2.77,0,0,0-.27-1.23,2.21,2.21,0,0,0-.77-.86,2.13,2.13,0,0,0-2.31,0,2.47,2.47,0,0,0-.8.95,2.94,2.94,0,0,0-.29,1.29v.44a3.54,3.54,0,0,0,.29,1.47,2.2,2.2,0,0,0,2.11,1.32,2.44,2.44,0,0,0,.89-.15,2.09,2.09,0,0,0,.64-.42,1.84,1.84,0,0,0,.39-.59l.78.26a2.27,2.27,0,0,1-.52.82,2.74,2.74,0,0,1-.92.61A3.26,3.26,0,0,1,454,442.45Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="e1442802-9f38-4210-a460-3cf578bb5417"
      d="M301.82,429.76l.48.91a2.34,2.34,0,0,0,.37.45,12.57,12.57,0,0,1,1.13,1.29,10.93,10.93,0,0,1,1.76,3.55,8.67,8.67,0,0,1,.38,2.42,8.54,8.54,0,0,1-2.49,6.29,8.11,8.11,0,0,1-.89.76c-.16,0-.24-.13-.31-.25a2,2,0,0,1-.25-.71,3.36,3.36,0,0,1-.08-.9v-.13s-.16-13.61-.1-13.68Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b4b027d5-194d-499b-967d-9b3b5453926a"
      d="M301.82,429.73s0,0-.06,0a.92.92,0,0,1-.17.55,2.91,2.91,0,0,1-.43.43,9.43,9.43,0,0,0-2.12,2.7,10.67,10.67,0,0,0-1.22,4.42,14.4,14.4,0,0,0,.35,3.11,9.51,9.51,0,0,0,2.48,3.9c.27.26.57.51.87.75.09,0,.1-.08.12-.14a2.74,2.74,0,0,0,.09-.39l.2-1.48-.11-13.86Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="aa810f29-748f-47b3-a965-61319ae4b3c8"
      d="M302.3,446a1.28,1.28,0,0,1,.25-.6.64.64,0,0,1-.28-.26,1.48,1.48,0,0,1-.14-.32,5.61,5.61,0,0,1-.21-1.28v-.25c-.05,0-.06.37-.06.42a9.67,9.67,0,0,1-.18,1.33,1.21,1.21,0,0,1-.16.52.15.15,0,0,0,0,.06,5.88,5.88,0,0,1,.26,1.6v.2c0,.24,0,.19.19.27a.89.89,0,0,1,.24.09c.06,0,.07-.05.07-.08l0-.33v-.91a2.32,2.32,0,0,1,0-.46Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M323.91,306.29h.86v6.82a3.25,3.25,0,0,1-.33,1.54,2.16,2.16,0,0,1-.9.92,2.86,2.86,0,0,1-1.33.3,3,3,0,0,1-1.31-.27,2.13,2.13,0,0,1-.91-.76,2,2,0,0,1-.33-1.16h.85a1.34,1.34,0,0,0,.22.74,1.51,1.51,0,0,0,.61.5,2.05,2.05,0,0,0,.87.18,1.76,1.76,0,0,0,.88-.21,1.39,1.39,0,0,0,.6-.66,2.46,2.46,0,0,0,.22-1.12Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M332.42,308.65a1.72,1.72,0,0,0-.7-1.25,2.51,2.51,0,0,0-1.55-.47,2.84,2.84,0,0,0-1.17.23,1.94,1.94,0,0,0-.78.62,1.54,1.54,0,0,0-.29.91,1.3,1.3,0,0,0,.17.66,1.51,1.51,0,0,0,.43.48,3.29,3.29,0,0,0,.61.34,4.6,4.6,0,0,0,.68.24l1,.29a6.46,6.46,0,0,1,.9.32,4,4,0,0,1,.83.48,2.31,2.31,0,0,1,.6.72,2,2,0,0,1,.23,1,2.49,2.49,0,0,1-.39,1.37,2.66,2.66,0,0,1-1.13.95,4,4,0,0,1-1.76.36,4.08,4.08,0,0,1-1.67-.32,2.78,2.78,0,0,1-1.12-.89,2.58,2.58,0,0,1-.46-1.32h.89a1.64,1.64,0,0,0,.37.95,2.13,2.13,0,0,0,.84.59,3.23,3.23,0,0,0,1.15.2,3.08,3.08,0,0,0,1.26-.24,2,2,0,0,0,.87-.67,1.61,1.61,0,0,0,.32-1,1.35,1.35,0,0,0-.25-.81,2.07,2.07,0,0,0-.7-.54,5.55,5.55,0,0,0-1-.39l-1.13-.34a4.23,4.23,0,0,1-1.73-.92,2,2,0,0,1-.61-1.49,2.26,2.26,0,0,1,.41-1.34,2.71,2.71,0,0,1,1.11-.91,3.76,3.76,0,0,1,1.57-.32,3.51,3.51,0,0,1,1.56.32,2.79,2.79,0,0,1,1.08.89,2.38,2.38,0,0,1,.42,1.28Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M343.22,311a5.9,5.9,0,0,1-.53,2.57,4.07,4.07,0,0,1-1.45,1.69,4.09,4.09,0,0,1-4.23,0,4.07,4.07,0,0,1-1.45-1.69,6.61,6.61,0,0,1,0-5.14,4.08,4.08,0,0,1,1.45-1.69,4,4,0,0,1,4.23,0,4,4,0,0,1,1.45,1.69A5.86,5.86,0,0,1,343.22,311Zm-.84,0a5.17,5.17,0,0,0-.43-2.18,3.27,3.27,0,0,0-1.16-1.39,3,3,0,0,0-1.66-.48,3,3,0,0,0-1.67.48,3.34,3.34,0,0,0-1.16,1.39,5.84,5.84,0,0,0,0,4.35,3.21,3.21,0,0,0,1.16,1.39,3.13,3.13,0,0,0,3.33,0,3.23,3.23,0,0,0,1.17-1.39A5.12,5.12,0,0,0,342.38,311Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M352.67,306.29v9.46h-.85l-5.58-7.92h-.07v7.92h-.87v-9.46h.84l5.6,7.93h.08v-7.93Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M361.19,315.87a3.31,3.31,0,0,1-1.52-.33,2.53,2.53,0,0,1-1-.93,2.48,2.48,0,0,1-.36-1.33,2,2,0,0,1,.22-1,2.78,2.78,0,0,1,.63-.82,9.37,9.37,0,0,1,1-.81l1.37-1a3.6,3.6,0,0,0,.74-.69,1.32,1.32,0,0,0,.26-.84,1.19,1.19,0,0,0-.36-.85,1.21,1.21,0,0,0-.92-.38,1.31,1.31,0,0,0-.71.2,1.55,1.55,0,0,0-.5.52,1.31,1.31,0,0,0-.18.66,1.47,1.47,0,0,0,.17.65,4,4,0,0,0,.45.68c.18.24.39.5.62.79l4.32,5.34h-1l-3.77-4.63c-.32-.38-.6-.73-.84-1a5.2,5.2,0,0,1-.59-.88,2.07,2.07,0,0,1-.21-.92,2.17,2.17,0,0,1,.28-1.08,2.06,2.06,0,0,1,.78-.74,2.35,2.35,0,0,1,1.14-.28,2.29,2.29,0,0,1,1.14.27,2.05,2.05,0,0,1,.75.71,1.94,1.94,0,0,1,.26,1,2,2,0,0,1-.33,1.14,4.17,4.17,0,0,1-1,1l-1.71,1.28a3.39,3.39,0,0,0-1,1,1.62,1.62,0,0,0-.27.77,1.73,1.73,0,0,0,.26.92,1.89,1.89,0,0,0,.72.68,2.27,2.27,0,0,0,1.05.26,2.79,2.79,0,0,0,2-.91,3.27,3.27,0,0,0,.67-1,4.31,4.31,0,0,0,.29-1.35h.8a4,4,0,0,1-.21,1.29,3.21,3.21,0,0,1-.42.87c-.16.23-.3.41-.42.55a1.71,1.71,0,0,1-.11.14l-.1.14a2.88,2.88,0,0,1-1.15.77A3.7,3.7,0,0,1,361.19,315.87Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M370.57,315.75h-.9l3.44-9.46H374l3.44,9.46h-.91l-3-8.34h-.06Zm.65-3.63h4.69v.78h-4.69Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M379.09,315.75v-9.46h3.05a3.31,3.31,0,0,1,1.68.39,2.43,2.43,0,0,1,1,1,3.18,3.18,0,0,1,.35,1.49,3.3,3.3,0,0,1-.34,1.49,2.57,2.57,0,0,1-1,1.05,3.41,3.41,0,0,1-1.67.38h-2.41v-.78h2.38a2.49,2.49,0,0,0,1.23-.27,1.89,1.89,0,0,0,.74-.77,2.44,2.44,0,0,0,.24-1.1,2.51,2.51,0,0,0-.24-1.11,1.86,1.86,0,0,0-.74-.76,2.56,2.56,0,0,0-1.24-.27H380v8.68Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M388.08,306.29v9.46h-.86v-9.46Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M394.25,315.75v-9.46h3.05a3.54,3.54,0,0,1,1.68.36,2.4,2.4,0,0,1,1,1,3.07,3.07,0,0,1,.35,1.47,3,3,0,0,1-.35,1.46,2.36,2.36,0,0,1-1,1,3.65,3.65,0,0,1-1.67.35h-2.6v-.79h2.57a2.81,2.81,0,0,0,1.23-.24,1.67,1.67,0,0,0,.74-.7,2.25,2.25,0,0,0,.24-1.07,2.21,2.21,0,0,0-.25-1.08,1.64,1.64,0,0,0-.73-.72,2.69,2.69,0,0,0-1.24-.25h-2.17v8.68Zm4.17-4.27,2.33,4.27h-1l-2.31-4.27Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M405.08,315.89a3,3,0,0,1-2.84-1.75,4.71,4.71,0,0,1,0-3.8,3.21,3.21,0,0,1,1.09-1.31,2.8,2.8,0,0,1,1.62-.47,3,3,0,0,1,1.11.21,2.78,2.78,0,0,1,1,.64,3.09,3.09,0,0,1,.67,1.08A4.27,4.27,0,0,1,408,312v.4h-5.54v-.72h4.7a2.71,2.71,0,0,0-.27-1.23,2.3,2.3,0,0,0-.76-.87,2.18,2.18,0,0,0-2.32.05,2.49,2.49,0,0,0-.8.95,2.91,2.91,0,0,0-.29,1.29v.43a3.55,3.55,0,0,0,.29,1.48,2.28,2.28,0,0,0,.84,1,2.31,2.31,0,0,0,1.28.35A2.19,2.19,0,0,0,406,315a1.78,1.78,0,0,0,.64-.42,1.83,1.83,0,0,0,.4-.58l.78.25a2.39,2.39,0,0,1-.53.83,2.72,2.72,0,0,1-.92.6A3.06,3.06,0,0,1,405.08,315.89Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M415.39,318.4h-.83v-4h-.07a3,3,0,0,1-.41.66,2,2,0,0,1-.69.57,2.21,2.21,0,0,1-1.08.23,2.59,2.59,0,0,1-1.53-.46,3.08,3.08,0,0,1-1-1.29,5.17,5.17,0,0,1,0-3.84,2.93,2.93,0,0,1,1-1.28,2.59,2.59,0,0,1,1.53-.46,2.16,2.16,0,0,1,1.08.23,2,2,0,0,1,.69.56,3.63,3.63,0,0,1,.4.66h.1v-1.36h.8Zm-.81-6.2a4.14,4.14,0,0,0-.26-1.5,2.3,2.3,0,0,0-.73-1,1.89,1.89,0,0,0-1.18-.37,1.84,1.84,0,0,0-1.2.38,2.28,2.28,0,0,0-.74,1,4,4,0,0,0-.25,1.47,4.18,4.18,0,0,0,.25,1.5,2.41,2.41,0,0,0,.75,1.05,1.83,1.83,0,0,0,1.19.39,1.79,1.79,0,0,0,1.17-.38,2.34,2.34,0,0,0,.74-1A4.27,4.27,0,0,0,414.58,312.2Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M422.05,313v-4.39h.82v7.1h-.82v-1.18H422a2.28,2.28,0,0,1-.79.91,2.33,2.33,0,0,1-1.32.36,2.39,2.39,0,0,1-1.21-.3,2.11,2.11,0,0,1-.83-.87,3.07,3.07,0,0,1-.3-1.42v-4.6h.83v4.55a1.8,1.8,0,0,0,.49,1.33,1.64,1.64,0,0,0,1.25.51,2.06,2.06,0,0,0,.93-.23,2,2,0,0,0,.72-.68A1.91,1.91,0,0,0,422.05,313Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M427.91,315.89a3.11,3.11,0,0,1-1.72-.46,3.07,3.07,0,0,1-1.12-1.29,4.82,4.82,0,0,1,0-3.8,3.15,3.15,0,0,1,1.1-1.31,2.75,2.75,0,0,1,1.61-.47,3.05,3.05,0,0,1,1.12.21,2.84,2.84,0,0,1,1,.64,3.27,3.27,0,0,1,.68,1.08,4.5,4.5,0,0,1,.25,1.55v.4h-5.54v-.72h4.69a2.71,2.71,0,0,0-.27-1.23,2.22,2.22,0,0,0-.76-.87,2,2,0,0,0-1.13-.31,2,2,0,0,0-1.18.36,2.4,2.4,0,0,0-.8.95,2.91,2.91,0,0,0-.3,1.29v.43a3.4,3.4,0,0,0,.3,1.48,2.2,2.2,0,0,0,.83,1,2.34,2.34,0,0,0,1.28.35,2.19,2.19,0,0,0,.88-.16,1.7,1.7,0,0,0,.64-.42,1.83,1.83,0,0,0,.4-.58l.78.25a2.26,2.26,0,0,1-.53.83,2.67,2.67,0,0,1-.91.6A3.15,3.15,0,0,1,427.91,315.89Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M437.35,310.21l-.75.21a2,2,0,0,0-.31-.57,1.48,1.48,0,0,0-.53-.42,2,2,0,0,0-.81-.15,2,2,0,0,0-1.18.34,1.06,1.06,0,0,0-.46.89,1,1,0,0,0,.32.74,2.25,2.25,0,0,0,1,.45l1.07.26a2.58,2.58,0,0,1,1.34.69,1.64,1.64,0,0,1,.45,1.18,1.84,1.84,0,0,1-.34,1.07,2.25,2.25,0,0,1-.92.73,3.5,3.5,0,0,1-1.38.26,2.88,2.88,0,0,1-1.71-.47,2.07,2.07,0,0,1-.86-1.35l.79-.19a1.55,1.55,0,0,0,.6.95,1.89,1.89,0,0,0,1.17.32,2.16,2.16,0,0,0,1.31-.36,1.13,1.13,0,0,0,.49-.92,1,1,0,0,0-.29-.73,1.78,1.78,0,0,0-.87-.43l-1.16-.27a2.66,2.66,0,0,1-1.36-.71,1.66,1.66,0,0,1-.45-1.19,1.75,1.75,0,0,1,.32-1,2.13,2.13,0,0,1,.88-.7,3,3,0,0,1,1.27-.25,2.49,2.49,0,0,1,1.56.44A2.42,2.42,0,0,1,437.35,310.21Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M442.05,308.65v.72h-3.38v-.72ZM439.72,307h.83v6.93a1.37,1.37,0,0,0,.15.7.75.75,0,0,0,.4.35,1.27,1.27,0,0,0,.52.11l.28,0,.2,0,.18.75a1.6,1.6,0,0,1-.3.08,1.8,1.8,0,0,1-.43,0,1.85,1.85,0,0,1-.87-.2,1.71,1.71,0,0,1-.69-.59,1.66,1.66,0,0,1-.27-1Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M448.55,310.21l-.75.21a2,2,0,0,0-.32-.57,1.4,1.4,0,0,0-.53-.42,1.87,1.87,0,0,0-.8-.15,2,2,0,0,0-1.18.34,1.07,1.07,0,0,0-.47.89,1,1,0,0,0,.32.74,2.3,2.3,0,0,0,1,.45l1.07.26a2.55,2.55,0,0,1,1.33.69,1.64,1.64,0,0,1,.45,1.18,1.83,1.83,0,0,1-.33,1.07,2.22,2.22,0,0,1-.93.73,3.42,3.42,0,0,1-1.37.26,2.86,2.86,0,0,1-1.71-.47,2.12,2.12,0,0,1-.87-1.35l.79-.19a1.55,1.55,0,0,0,.6.95,1.93,1.93,0,0,0,1.17.32,2.16,2.16,0,0,0,1.31-.36,1.11,1.11,0,0,0,.49-.92,1,1,0,0,0-.29-.73,1.78,1.78,0,0,0-.86-.43l-1.16-.27a2.69,2.69,0,0,1-1.37-.71,1.82,1.82,0,0,1-.12-2.22,2.1,2.1,0,0,1,.87-.7,3.08,3.08,0,0,1,1.28-.25,2.29,2.29,0,0,1,2.4,1.65Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="f1a52f01-54ca-44f6-829d-95d54c3d89fc"
      d="M304.66,295.89H293.53a2.79,2.79,0,0,0-2.78,2.79v22.24a2.79,2.79,0,0,0,2.78,2.79h16.69a2.79,2.79,0,0,0,2.78-2.79V304.24Zm-8.32,20.45c0,1.34-.64,1.8-1.66,1.8a2.77,2.77,0,0,1-.78-.11l.12-.85a1.66,1.66,0,0,0,.54.08c.45,0,.73-.2.73-.93v-2.95h1.06v3Zm2,1.8a3.05,3.05,0,0,1-1.33-.29l.22-.88a2.62,2.62,0,0,0,1.18.3c.48,0,.74-.21.74-.51s-.22-.46-.79-.66c-.78-.27-1.28-.71-1.28-1.39s.66-1.41,1.77-1.41a2.81,2.81,0,0,1,1.2.24l-.24.85a2.24,2.24,0,0,0-1-.22c-.46,0-.68.21-.68.45s.26.43.87.66c.83.31,1.22.74,1.22,1.4S299.66,318.14,298.37,318.14Zm4.6,0a2.19,2.19,0,0,1-2.21-2.38,2.27,2.27,0,0,1,2.29-2.46,2.18,2.18,0,0,1,2.22,2.37A2.23,2.23,0,0,1,303,318.14Zm6.88-.07h-1.12l-1-1.81a17,17,0,0,1-.81-1.66h0c0,.62,0,1.28,0,2.05v1.41h-1v-4.69h1.24l1,1.72a14.73,14.73,0,0,1,.77,1.6h0c-.06-.62-.09-1.25-.09-1.95v-1.37h1v4.69Zm-5.19-12.44h-1.39v-6.95l7,6.95Z"
      transform="translate(-5.07 -32.75)"
    />
    <g id="b121d19f-e598-4bb7-a9f0-daa7a5b3741d" data-name="JS">
      <path
        d="M204.41,303l2.72-1.68s.1.14.19.29a3,3,0,0,0,1.25,1.28,1.91,1.91,0,0,0,2.34-.72c.3-.52.2-2.2.2-4.05,0-2.89,0-5.77,0-8.67h3.35v.08c0,3.1,0,6.17,0,9.24,0,1.9.17,3.61-.6,5a4.23,4.23,0,0,1-2.7,2.11,6.6,6.6,0,0,1-4.79-.61A5.23,5.23,0,0,1,204.41,303Z"
        transform="translate(-5.07 -32.75)"
      />
      <path
        d="M224.55,300.83c-.39-1.43-3.37-1.76-5.41-3.29a4.69,4.69,0,0,1-.85-6.68,4.93,4.93,0,0,1,2.56-1.5l1.05-.14a5.06,5.06,0,0,1,4.24,1.55,6.31,6.31,0,0,1,.87,1.17c-1.08.67-1.08.67-2.62,1.68a2.45,2.45,0,0,0-1.46-1.35,1.7,1.7,0,0,0-2.26,1,1.81,1.81,0,0,0,.06,1.06c.37.82,1.59,1.18,2.69,1.69,3.16,1.28,4.22,2.66,4.48,4.29l-.06-.1a5,5,0,0,1-.12,2.47c-1.16,4-7.66,4.12-10.26,1.51a6.08,6.08,0,0,1-1.21-1.66L219,301a4,4,0,0,0,2.63,2C223.25,303.14,224.91,302.58,224.55,300.83Z"
        transform="translate(-5.07 -32.75)"
      />
      <path
        class="fbe16779-d3b1-419d-876d-1666ca6c58f1"
        d="M195,273v35.85h35.85V273Zm18.89,30.8a2.88,2.88,0,0,1-.22.38,2.11,2.11,0,0,1-.26.35,1.51,1.51,0,0,1-.21.23,1.12,1.12,0,0,1-.2.18.65.65,0,0,1-.18.15l-.31.22h0a.57.57,0,0,1-.13.08,3.47,3.47,0,0,1-.33.18,2.87,2.87,0,0,1-.4.18,3.26,3.26,0,0,1-.44.15,6.6,6.6,0,0,1-4.79-.61,5.23,5.23,0,0,1-2-2.27l2.72-1.68s.1.14.19.29a3,3,0,0,0,1.25,1.28,1.91,1.91,0,0,0,2.34-.72c.3-.52.2-2.2.2-4.05,0-2.89,0-5.77,0-8.67h3.35v.08c0,3.1,0,6.17,0,9.24C214.48,300.7,214.65,302.41,213.88,303.8Zm13.84-1.13c-1.16,4-7.66,4.12-10.26,1.51a6.08,6.08,0,0,1-1.21-1.66L219,301a4,4,0,0,0,2.63,2c1.64.2,3.3-.36,2.94-2.11-.39-1.43-3.37-1.76-5.41-3.29a4.69,4.69,0,0,1-.85-6.68,4.93,4.93,0,0,1,2.56-1.5l1.05-.14a5.06,5.06,0,0,1,4.24,1.55,6.31,6.31,0,0,1,.87,1.17c-1.08.67-1.08.67-2.62,1.68a2.45,2.45,0,0,0-1.46-1.35,1.7,1.7,0,0,0-2.26,1,1.81,1.81,0,0,0,.06,1.06c.37.82,1.59,1.18,2.69,1.69,3.16,1.28,4.22,2.66,4.48,4.29l-.06-.1A5,5,0,0,1,227.72,302.67Z"
        transform="translate(-5.07 -32.75)"
      />
    </g>
    <path
      d="M330.72,389.93v9.46h-.84l-5.59-7.92h-.07v7.92h-.87v-9.46h.84l5.6,7.93h.08v-7.93Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M335.81,399.53a2.87,2.87,0,0,1-1.62-.46,3.09,3.09,0,0,1-1.1-1.29,4.29,4.29,0,0,1-.4-1.91,4.37,4.37,0,0,1,.4-1.92,3.09,3.09,0,0,1,1.1-1.29,3.08,3.08,0,0,1,3.24,0,3.19,3.19,0,0,1,1.1,1.3,4.29,4.29,0,0,1,.4,1.91,4.43,4.43,0,0,1-.39,1.91,3.24,3.24,0,0,1-1.1,1.29A2.9,2.9,0,0,1,335.81,399.53Zm0-.75a2,2,0,0,0,1.25-.4,2.38,2.38,0,0,0,.78-1.05,4.08,4.08,0,0,0,0-2.92,2.47,2.47,0,0,0-.78-1.06,2.15,2.15,0,0,0-2.5,0,2.56,2.56,0,0,0-.78,1.06,4.23,4.23,0,0,0,0,2.92,2.46,2.46,0,0,0,.78,1.05A2,2,0,0,0,335.81,398.78Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M343.29,399.53a2.59,2.59,0,0,1-1.53-.46,3.16,3.16,0,0,1-1-1.29,5.17,5.17,0,0,1,0-3.84,3,3,0,0,1,1-1.28,2.59,2.59,0,0,1,1.53-.46,2.19,2.19,0,0,1,1.08.23,2,2,0,0,1,.69.56,3.42,3.42,0,0,1,.4.66h.07v-3.72h.83v9.46h-.8v-1.32h-.1a2.64,2.64,0,0,1-.41.66,2,2,0,0,1-.69.57A2.21,2.21,0,0,1,343.29,399.53Zm.1-.75a1.79,1.79,0,0,0,1.17-.38,2.34,2.34,0,0,0,.74-1,4.27,4.27,0,0,0,.26-1.53,4.14,4.14,0,0,0-.26-1.5,2.3,2.3,0,0,0-.73-1,1.89,1.89,0,0,0-1.18-.37,1.84,1.84,0,0,0-1.2.38,2.5,2.5,0,0,0-.74,1,4,4,0,0,0-.25,1.47,4.18,4.18,0,0,0,.25,1.5,2.47,2.47,0,0,0,.74,1.05A1.88,1.88,0,0,0,343.39,398.78Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M351.58,399.53a3.11,3.11,0,0,1-1.72-.46,3.07,3.07,0,0,1-1.12-1.29,4.82,4.82,0,0,1,0-3.8,3.21,3.21,0,0,1,1.09-1.31,2.88,2.88,0,0,1,1.62-.47,3.05,3.05,0,0,1,1.12.21,2.84,2.84,0,0,1,1,.64,3.09,3.09,0,0,1,.67,1.08,4.27,4.27,0,0,1,.25,1.55v.4h-5.54v-.72h4.7a2.71,2.71,0,0,0-.27-1.23,2.29,2.29,0,0,0-.76-.87,2,2,0,0,0-1.13-.31,2,2,0,0,0-1.18.36,2.32,2.32,0,0,0-.8.95,2.91,2.91,0,0,0-.3,1.29V396a3.4,3.4,0,0,0,.3,1.48,2.2,2.2,0,0,0,.83,1,2.23,2.23,0,0,0,1.28.35,2.19,2.19,0,0,0,.88-.16,1.7,1.7,0,0,0,.64-.42,1.83,1.83,0,0,0,.4-.58l.78.25a2.39,2.39,0,0,1-.53.83,3,3,0,0,1-.91.61A3.33,3.33,0,0,1,351.58,399.53Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M362.44,399.52a3.31,3.31,0,0,1-1.52-.34,2.6,2.6,0,0,1-1-.93,2.47,2.47,0,0,1-.36-1.32,2.07,2.07,0,0,1,.22-1,3.16,3.16,0,0,1,.63-.82,10.47,10.47,0,0,1,1-.81l1.37-1a3.68,3.68,0,0,0,.75-.69,1.37,1.37,0,0,0,.25-.84,1.26,1.26,0,0,0-1.28-1.23,1.31,1.31,0,0,0-.71.2,1.52,1.52,0,0,0-.49.52,1.23,1.23,0,0,0-.19.66,1.43,1.43,0,0,0,.17.65,4,4,0,0,0,.45.68c.19.24.39.5.63.79l4.32,5.34h-1l-3.77-4.63c-.31-.38-.6-.73-.84-1a4.58,4.58,0,0,1-.58-.88,2,2,0,0,1-.22-.92,2.08,2.08,0,0,1,.29-1.08,2,2,0,0,1,.77-.74,2.34,2.34,0,0,1,1.14-.27,2.29,2.29,0,0,1,1.14.26,2.05,2.05,0,0,1,.75.71,2,2,0,0,1,.26,1,2,2,0,0,1-.33,1.14,4.17,4.17,0,0,1-1,1l-1.71,1.28a3.53,3.53,0,0,0-1,1,1.61,1.61,0,0,0-.27.78,1.72,1.72,0,0,0,.26.91,2,2,0,0,0,.72.68,2.28,2.28,0,0,0,1.06.26,3,3,0,0,0,1.11-.24,2.9,2.9,0,0,0,.93-.67,3.27,3.27,0,0,0,.67-1,4,4,0,0,0,.29-1.35h.8a4,4,0,0,1-.21,1.29,3.21,3.21,0,0,1-.42.87,5.46,5.46,0,0,1-.42.55l-.1.14-.11.14a2.88,2.88,0,0,1-1.15.77A3.67,3.67,0,0,1,362.44,399.52Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M371.81,399.39v-9.46h5.49v.78h-4.63v3.56H377V395h-4.33v3.57h4.72v.78Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M379.86,392.29l1.83,3,1.83-3h1l-2.28,3.55,2.28,3.55h-1l-1.83-2.93-1.83,2.93h-.95l2.25-3.55-2.25-3.55Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M386.19,402v-9.75h.8v1.36h.1a3.42,3.42,0,0,1,.4-.66,2.11,2.11,0,0,1,.68-.56,2.21,2.21,0,0,1,1.08-.23,2.59,2.59,0,0,1,1.54.46,3,3,0,0,1,1,1.28,4.77,4.77,0,0,1,.36,1.92,4.6,4.6,0,0,1-.37,1.92,3,3,0,0,1-1,1.29,2.58,2.58,0,0,1-1.52.46,2.19,2.19,0,0,1-1.08-.23,2.09,2.09,0,0,1-.7-.57,2.59,2.59,0,0,1-.4-.66H387v4Zm.81-6.2a4.27,4.27,0,0,0,.25,1.53,2.36,2.36,0,0,0,.75,1,1.78,1.78,0,0,0,1.16.38,1.88,1.88,0,0,0,1.2-.39,2.39,2.39,0,0,0,.74-1.05,4.18,4.18,0,0,0,.26-1.5,4.27,4.27,0,0,0-.25-1.47,2.5,2.5,0,0,0-.74-1,1.88,1.88,0,0,0-1.21-.38,1.84,1.84,0,0,0-1.17.37,2.24,2.24,0,0,0-.74,1A4.14,4.14,0,0,0,387,395.84Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M394,399.39v-7.1h.8v1.09h.06a1.8,1.8,0,0,1,.74-.86,2.21,2.21,0,0,1,1.2-.33h.43V393l-.19,0-.3,0a2,2,0,0,0-1,.24,1.74,1.74,0,0,0-.93,1.58v4.58Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M401.27,399.53a3.11,3.11,0,0,1-1.72-.46,3.07,3.07,0,0,1-1.12-1.29,4.82,4.82,0,0,1,0-3.8,3.15,3.15,0,0,1,1.1-1.31,2.82,2.82,0,0,1,1.61-.47,3.05,3.05,0,0,1,1.12.21,2.84,2.84,0,0,1,1,.64,3.09,3.09,0,0,1,.67,1.08,4.27,4.27,0,0,1,.25,1.55v.4H398.6v-.72h4.7a2.71,2.71,0,0,0-.27-1.23,2.29,2.29,0,0,0-.76-.87,2,2,0,0,0-1.13-.31,2,2,0,0,0-1.18.36,2.4,2.4,0,0,0-.8.95,2.91,2.91,0,0,0-.3,1.29V396a3.4,3.4,0,0,0,.3,1.48,2.2,2.2,0,0,0,.83,1,2.26,2.26,0,0,0,1.28.35,2.19,2.19,0,0,0,.88-.16,1.7,1.7,0,0,0,.64-.42,1.83,1.83,0,0,0,.4-.58l.78.25a2.39,2.39,0,0,1-.53.83,2.85,2.85,0,0,1-.91.61A3.33,3.33,0,0,1,401.27,399.53Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M410.71,393.85l-.75.21a2,2,0,0,0-.31-.57,1.43,1.43,0,0,0-.53-.41,1.8,1.8,0,0,0-.81-.16,2,2,0,0,0-1.18.34,1.06,1.06,0,0,0-.46.89.93.93,0,0,0,.32.74,2.25,2.25,0,0,0,1,.45l1.07.26a2.66,2.66,0,0,1,1.34.69,1.63,1.63,0,0,1,.44,1.18,1.81,1.81,0,0,1-.33,1.07,2.25,2.25,0,0,1-.92.73,3.5,3.5,0,0,1-1.38.26,2.88,2.88,0,0,1-1.71-.47,2.07,2.07,0,0,1-.86-1.35l.79-.19a1.55,1.55,0,0,0,.6,1,1.89,1.89,0,0,0,1.17.32,2.19,2.19,0,0,0,1.31-.36,1.13,1.13,0,0,0,.49-.92,1,1,0,0,0-.29-.73,1.78,1.78,0,0,0-.87-.43l-1.16-.27a2.66,2.66,0,0,1-1.36-.71,1.66,1.66,0,0,1-.45-1.19,1.75,1.75,0,0,1,.32-1,2.13,2.13,0,0,1,.88-.7,3,3,0,0,1,1.27-.25,2.49,2.49,0,0,1,1.56.44A2.42,2.42,0,0,1,410.71,393.85Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M417.38,393.85l-.75.21a2,2,0,0,0-.32-.57,1.29,1.29,0,0,0-.53-.41,1.73,1.73,0,0,0-.8-.16,2,2,0,0,0-1.18.34,1.07,1.07,0,0,0-.47.89,1,1,0,0,0,.32.74,2.3,2.3,0,0,0,1,.45l1.07.26a2.7,2.7,0,0,1,1.34.69,1.67,1.67,0,0,1,.44,1.18,1.81,1.81,0,0,1-.33,1.07,2.22,2.22,0,0,1-.93.73,3.42,3.42,0,0,1-1.37.26,2.88,2.88,0,0,1-1.71-.47,2.12,2.12,0,0,1-.87-1.35l.79-.19a1.55,1.55,0,0,0,.6,1,1.94,1.94,0,0,0,1.18.32,2.13,2.13,0,0,0,1.3-.36,1.11,1.11,0,0,0,.49-.92,1,1,0,0,0-.28-.73,1.82,1.82,0,0,0-.87-.43l-1.16-.27a2.69,2.69,0,0,1-1.37-.71,1.7,1.7,0,0,1-.44-1.19,1.75,1.75,0,0,1,.32-1,2.07,2.07,0,0,1,.88-.7,3,3,0,0,1,1.27-.25,2.49,2.49,0,0,1,1.56.44A2.42,2.42,0,0,1,417.38,393.85Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M426.69,389.93h.86v6.82a3.22,3.22,0,0,1-.33,1.54,2.16,2.16,0,0,1-.9.92,2.87,2.87,0,0,1-1.33.31,3.07,3.07,0,0,1-1.31-.28,2.19,2.19,0,0,1-.91-.76,2,2,0,0,1-.33-1.16h.85a1.34,1.34,0,0,0,.22.74,1.51,1.51,0,0,0,.61.5,2.05,2.05,0,0,0,.87.18,1.83,1.83,0,0,0,.89-.21,1.42,1.42,0,0,0,.59-.66,2.43,2.43,0,0,0,.22-1.12Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M435.2,392.29a1.72,1.72,0,0,0-.7-1.25,2.51,2.51,0,0,0-1.55-.47,2.79,2.79,0,0,0-1.16.23,1.91,1.91,0,0,0-.79.62,1.53,1.53,0,0,0-.28.91,1.29,1.29,0,0,0,.16.66,1.52,1.52,0,0,0,.43.48,3.29,3.29,0,0,0,.61.34,4.6,4.6,0,0,0,.68.24l1,.29a6.46,6.46,0,0,1,.9.32,3.63,3.63,0,0,1,.83.49,2.32,2.32,0,0,1,.61.71,2.12,2.12,0,0,1,.22,1,2.49,2.49,0,0,1-.39,1.37,2.66,2.66,0,0,1-1.13.95,3.9,3.9,0,0,1-1.75.36,4.09,4.09,0,0,1-1.68-.32,2.78,2.78,0,0,1-1.12-.89,2.49,2.49,0,0,1-.45-1.32h.88a1.71,1.71,0,0,0,.37,1,2.13,2.13,0,0,0,.84.59,3.28,3.28,0,0,0,1.16.2,3.06,3.06,0,0,0,1.25-.24,2.1,2.1,0,0,0,.87-.66,1.66,1.66,0,0,0,.32-1,1.23,1.23,0,0,0-.25-.8,2,2,0,0,0-.7-.55,5.55,5.55,0,0,0-1-.39l-1.13-.34a4.11,4.11,0,0,1-1.73-.92,2,2,0,0,1-.61-1.49,2.26,2.26,0,0,1,.41-1.34,2.71,2.71,0,0,1,1.11-.91,3.76,3.76,0,0,1,1.57-.32,3.65,3.65,0,0,1,1.56.32,2.79,2.79,0,0,1,1.08.89,2.3,2.3,0,0,1,.42,1.28Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b5924130-c92b-42cf-859d-aacb5cef6a84"
      d="M301.87,406.44a2.08,2.08,0,0,1-.94-.24l-3-1.77c-.44-.24-.22-.33-.09-.38a5.28,5.28,0,0,0,1.35-.6.22.22,0,0,1,.22,0l2.28,1.37a.32.32,0,0,0,.27,0l8.91-5.16a.27.27,0,0,0,.14-.25v-10.3a.27.27,0,0,0-.14-.25L302,383.74a.32.32,0,0,0-.27,0l-8.92,5.14a.28.28,0,0,0-.13.25v10.3a.26.26,0,0,0,.13.24l2.44,1.41c1.32.67,2.14-.11,2.14-.89V390a.28.28,0,0,1,.27-.27h1.14a.28.28,0,0,1,.27.27v10.17a2.48,2.48,0,0,1-2.64,2.79,3.82,3.82,0,0,1-2.05-.56L292,401.08a1.88,1.88,0,0,1-.94-1.63v-10.3a1.88,1.88,0,0,1,.94-1.63l8.91-5.16a2,2,0,0,1,1.89,0l8.91,5.16a1.88,1.88,0,0,1,.94,1.63v10.3a1.88,1.88,0,0,1-.94,1.63l-8.91,5.16A2.25,2.25,0,0,1,301.87,406.44Zm2.75-7.08c-3.91,0-4.71-1.79-4.71-3.31a.28.28,0,0,1,.27-.27h1.16a.23.23,0,0,1,.24.23c.18,1.18.69,1.76,3.06,1.76,1.88,0,2.69-.42,2.69-1.43,0-.58-.23-1-3.15-1.29-2.44-.25-4-.79-4-2.73s1.52-2.88,4.07-2.88c2.86,0,4.27,1,4.44,3.13a.36.36,0,0,1-.06.2.34.34,0,0,1-.18.09h-1.16a.27.27,0,0,1-.25-.2c-.27-1.23-1-1.63-2.79-1.63-2.06,0-2.3.71-2.3,1.25s.28.84,3.06,1.2,4,.87,4,2.8S307.46,399.36,304.62,399.36Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M324.35,358.17v-9.45h3a3.55,3.55,0,0,1,1.69.36,2.46,2.46,0,0,1,1,1,3.07,3.07,0,0,1,.34,1.47,3,3,0,0,1-.34,1.46,2.44,2.44,0,0,1-1,1,3.65,3.65,0,0,1-1.67.35h-2.6v-.79h2.57a2.75,2.75,0,0,0,1.23-.24,1.68,1.68,0,0,0,.74-.7,2.25,2.25,0,0,0,.24-1.07,2.32,2.32,0,0,0-.24-1.08,1.72,1.72,0,0,0-.74-.72,2.68,2.68,0,0,0-1.24-.26h-2.16v8.68Zm4.17-4.26,2.33,4.26h-1l-2.3-4.26Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M335.18,358.32a3.11,3.11,0,0,1-1.72-.46,3.07,3.07,0,0,1-1.12-1.29,4.82,4.82,0,0,1,0-3.8,3.09,3.09,0,0,1,1.1-1.31,2.75,2.75,0,0,1,1.61-.47,3.05,3.05,0,0,1,1.12.21,2.84,2.84,0,0,1,1,.64,3.09,3.09,0,0,1,.67,1.08,4.27,4.27,0,0,1,.25,1.55v.4h-5.54v-.72h4.7a2.71,2.71,0,0,0-.27-1.23,2.15,2.15,0,0,0-.76-.87,2,2,0,0,0-1.13-.32,2,2,0,0,0-1.18.36,2.47,2.47,0,0,0-.8,1,3,3,0,0,0-.3,1.3v.43a3.43,3.43,0,0,0,.3,1.48,2.2,2.2,0,0,0,.83,1,2.34,2.34,0,0,0,1.28.35,2.19,2.19,0,0,0,.88-.16,1.7,1.7,0,0,0,.64-.42,1.83,1.83,0,0,0,.4-.58l.78.25a2.49,2.49,0,0,1-.53.83,2.67,2.67,0,0,1-.91.6A3.15,3.15,0,0,1,335.18,358.32Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M341.84,358.34a2.64,2.64,0,0,1-1.17-.26,2,2,0,0,1-.85-.73,2,2,0,0,1-.31-1.16,1.71,1.71,0,0,1,.2-.88,1.58,1.58,0,0,1,.56-.59,2.94,2.94,0,0,1,.86-.37,8.27,8.27,0,0,1,1.1-.21l1-.13a1.66,1.66,0,0,0,.63-.17.41.41,0,0,0,.22-.39v-.16a1.55,1.55,0,0,0-.43-1.15,1.71,1.71,0,0,0-1.24-.42,2.17,2.17,0,0,0-1.25.34,1.9,1.9,0,0,0-.68.8l-.78-.29a2.37,2.37,0,0,1,.67-.93,2.63,2.63,0,0,1,.95-.5,3.82,3.82,0,0,1,1.06-.15,3.64,3.64,0,0,1,.85.1,2.44,2.44,0,0,1,.82.37,1.81,1.81,0,0,1,.61.72,2.55,2.55,0,0,1,.24,1.18v4.81h-.82v-1.12h0a2.31,2.31,0,0,1-1.17,1.1A2.7,2.7,0,0,1,341.84,358.34Zm.11-.76a2.1,2.1,0,0,0,1.14-.29,2,2,0,0,0,.74-.77,2.26,2.26,0,0,0,.26-1.07v-1a.88.88,0,0,1-.31.16l-.49.12-.56.09-.5.06a4.83,4.83,0,0,0-1,.23,1.44,1.44,0,0,0-.65.44,1.05,1.05,0,0,0-.23.7,1.19,1.19,0,0,0,.46,1A1.84,1.84,0,0,0,342,357.58Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M349.86,358.32a2.93,2.93,0,0,1-1.65-.47,3.06,3.06,0,0,1-1.09-1.31,4.33,4.33,0,0,1-.39-1.88,4.45,4.45,0,0,1,.39-1.9,3.2,3.2,0,0,1,1.1-1.3,2.81,2.81,0,0,1,1.63-.47,3,3,0,0,1,1.29.27,2.55,2.55,0,0,1,.95.77,2.44,2.44,0,0,1,.47,1.16h-.83a1.83,1.83,0,0,0-.62-1,1.86,1.86,0,0,0-1.25-.43,2.08,2.08,0,0,0-1.2.37,2.43,2.43,0,0,0-.81,1,3.6,3.6,0,0,0-.3,1.51,3.83,3.83,0,0,0,.29,1.53,2.43,2.43,0,0,0,.81,1,2.07,2.07,0,0,0,1.21.37,2,2,0,0,0,.86-.18,1.93,1.93,0,0,0,.66-.5,1.87,1.87,0,0,0,.36-.78h.83a2.6,2.6,0,0,1-.46,1.14,2.65,2.65,0,0,1-.93.79A3,3,0,0,1,349.86,358.32Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M357.12,351.08v.72h-3.38v-.72Zm-2.33-1.7h.83v6.94a1.37,1.37,0,0,0,.15.7.84.84,0,0,0,.4.35,1.31,1.31,0,0,0,.52.11l.28,0,.2,0,.18.75-.3.08a1.8,1.8,0,0,1-.43,0,2,2,0,0,1-.87-.2,1.71,1.71,0,0,1-.69-.59,1.68,1.68,0,0,1-.27-1Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M366.39,348.72h.87v6.82a3.37,3.37,0,0,1-.34,1.54,2.11,2.11,0,0,1-.9.92,2.85,2.85,0,0,1-1.32.3,3.11,3.11,0,0,1-1.32-.27,2.19,2.19,0,0,1-.9-.77,2,2,0,0,1-.33-1.15H363a1.26,1.26,0,0,0,.22.74,1.55,1.55,0,0,0,.6.5,2.07,2.07,0,0,0,.88.18,1.69,1.69,0,0,0,.88-.22,1.46,1.46,0,0,0,.6-.65,2.6,2.6,0,0,0,.21-1.12Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M374.9,351.08a1.71,1.71,0,0,0-.69-1.25,2.56,2.56,0,0,0-1.56-.47,2.79,2.79,0,0,0-1.16.23,1.91,1.91,0,0,0-.79.62,1.53,1.53,0,0,0-.28.91,1.29,1.29,0,0,0,.16.66,1.66,1.66,0,0,0,.44.48,2.75,2.75,0,0,0,.61.34,4.49,4.49,0,0,0,.67.24l1,.29a7.08,7.08,0,0,1,.91.32,3.92,3.92,0,0,1,.82.48,2.34,2.34,0,0,1,.61.72,2.12,2.12,0,0,1,.23,1,2.36,2.36,0,0,1-.4,1.36,2.58,2.58,0,0,1-1.12,1,4.46,4.46,0,0,1-3.44,0,2.72,2.72,0,0,1-1.11-.88,2.5,2.5,0,0,1-.46-1.32h.89a1.7,1.7,0,0,0,.36.95,2.13,2.13,0,0,0,.84.59,3.28,3.28,0,0,0,1.16.2,3.15,3.15,0,0,0,1.26-.24,2.07,2.07,0,0,0,.86-.67,1.55,1.55,0,0,0,.32-1,1.28,1.28,0,0,0-.25-.81,1.91,1.91,0,0,0-.69-.54,6.72,6.72,0,0,0-1-.4l-1.14-.33a4.18,4.18,0,0,1-1.72-.92,2,2,0,0,1-.62-1.49,2.26,2.26,0,0,1,.41-1.34,2.71,2.71,0,0,1,1.11-.91,3.64,3.64,0,0,1,1.57-.32,3.51,3.51,0,0,1,1.56.32,2.7,2.7,0,0,1,1.08.89,2.28,2.28,0,0,1,.43,1.28Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M384,358.3a3.21,3.21,0,0,1-1.52-.34,2.45,2.45,0,0,1-1.37-2.25,2,2,0,0,1,.22-1,3.2,3.2,0,0,1,.63-.83,10.47,10.47,0,0,1,1-.81l1.37-1a3.19,3.19,0,0,0,.75-.69,1.32,1.32,0,0,0,.25-.83,1.22,1.22,0,0,0-.35-.85,1.24,1.24,0,0,0-.93-.38,1.31,1.31,0,0,0-.71.2,1.44,1.44,0,0,0-.49.52,1.32,1.32,0,0,0-.19.66,1.47,1.47,0,0,0,.17.65,4.41,4.41,0,0,0,.45.68l.63.79,4.32,5.33h-1l-3.77-4.62c-.31-.38-.6-.73-.84-1a4.58,4.58,0,0,1-.58-.88,2.1,2.1,0,0,1,.07-2,2,2,0,0,1,.77-.74,2.24,2.24,0,0,1,1.14-.28,2.29,2.29,0,0,1,1.14.27,2.05,2.05,0,0,1,.75.71,1.94,1.94,0,0,1,.26,1,2,2,0,0,1-.33,1.14,4.31,4.31,0,0,1-.95,1L383.14,354a3.53,3.53,0,0,0-1,1,1.55,1.55,0,0,0-.26.77,1.72,1.72,0,0,0,.25.92,2,2,0,0,0,.72.68,2.16,2.16,0,0,0,1.06.25,2.79,2.79,0,0,0,1.11-.23,2.9,2.9,0,0,0,.93-.67,3.38,3.38,0,0,0,.67-1,4,4,0,0,0,.29-1.35h.8a4.26,4.26,0,0,1-.2,1.29,3.92,3.92,0,0,1-.43.87c-.16.23-.3.41-.42.55l-.1.14-.11.14a3,3,0,0,1-1.15.77A3.66,3.66,0,0,1,384,358.3Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M393.33,358.17v-9.45h3.05a3.61,3.61,0,0,1,1.69.36,2.38,2.38,0,0,1,1,1,3.07,3.07,0,0,1,.35,1.47,3,3,0,0,1-.35,1.46,2.36,2.36,0,0,1-1,1,3.65,3.65,0,0,1-1.67.35h-2.6v-.79h2.57a2.75,2.75,0,0,0,1.23-.24,1.68,1.68,0,0,0,.74-.7,2.25,2.25,0,0,0,.24-1.07,2.21,2.21,0,0,0-.25-1.08,1.64,1.64,0,0,0-.73-.72,2.68,2.68,0,0,0-1.24-.26h-2.17v8.68Zm4.17-4.26,2.33,4.26h-1l-2.3-4.26Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M404.16,358.32a3.11,3.11,0,0,1-1.72-.46,3.07,3.07,0,0,1-1.12-1.29,4.71,4.71,0,0,1,0-3.8,3.14,3.14,0,0,1,1.09-1.31A2.8,2.8,0,0,1,404,351a3,3,0,0,1,1.11.21,2.78,2.78,0,0,1,1,.64,3.09,3.09,0,0,1,.67,1.08,4.27,4.27,0,0,1,.25,1.55v.4h-5.54v-.72h4.7a2.71,2.71,0,0,0-.27-1.23,2.23,2.23,0,0,0-.76-.87,2,2,0,0,0-1.13-.32,2,2,0,0,0-1.18.36,2.5,2.5,0,0,0-.81,1,3,3,0,0,0-.29,1.3v.43a3.58,3.58,0,0,0,.29,1.48,2.28,2.28,0,0,0,.84,1,2.31,2.31,0,0,0,1.28.35,2.19,2.19,0,0,0,.88-.16,1.78,1.78,0,0,0,.64-.42,1.83,1.83,0,0,0,.4-.58l.78.25a2.49,2.49,0,0,1-.53.83,2.77,2.77,0,0,1-.91.6A3.15,3.15,0,0,1,404.16,358.32Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M411.39,358.32a2.59,2.59,0,0,1-1.53-.46,3.08,3.08,0,0,1-1-1.29,5.17,5.17,0,0,1,0-3.84,2.93,2.93,0,0,1,1-1.28,2.59,2.59,0,0,1,1.53-.46,2.34,2.34,0,0,1,1.08.22,2.19,2.19,0,0,1,.69.57,3.32,3.32,0,0,1,.4.66h.07v-3.72h.83v9.45h-.8v-1.31h-.1a3.51,3.51,0,0,1-.41.66,2,2,0,0,1-.69.57A2.33,2.33,0,0,1,411.39,358.32Zm.1-.75a1.85,1.85,0,0,0,1.17-.38,2.32,2.32,0,0,0,.74-1,4.51,4.51,0,0,0,0-3,2.24,2.24,0,0,0-.73-1,1.89,1.89,0,0,0-1.18-.38,1.84,1.84,0,0,0-1.2.39,2.36,2.36,0,0,0-.74,1,4,4,0,0,0-.25,1.47,4.12,4.12,0,0,0,.25,1.49,2.44,2.44,0,0,0,.74,1.06A1.88,1.88,0,0,0,411.49,357.57Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M421.32,355.46v-4.38h.82v7.09h-.82V357h-.07a2.19,2.19,0,0,1-.79.9,2.25,2.25,0,0,1-1.32.37,2.38,2.38,0,0,1-1.2-.3,2.08,2.08,0,0,1-.83-.88,3,3,0,0,1-.3-1.41v-4.6h.82v4.55a1.82,1.82,0,0,0,.49,1.33,1.68,1.68,0,0,0,1.26.51,2,2,0,0,0,.92-.23,1.9,1.9,0,0,0,.73-.68A2,2,0,0,0,421.32,355.46Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M424.82,351.08l1.82,3,1.83-3h1l-2.28,3.55,2.28,3.54h-1l-1.83-2.92-1.82,2.92h-1l2.26-3.54-2.26-3.55Z"
      transform="translate(-5.07 -32.75)"
    />
    <circle
      class="b834dfc7-277f-4c9e-a656-71c79c5ef852"
      cx="296.8"
      cy="319.89"
      r="2.49"
    />
    <path
      class="b834dfc7-277f-4c9e-a656-71c79c5ef852"
      d="M294.9,357.59l-.56-.14c-4.09-1-6.44-2.79-6.44-4.82s2.35-3.78,6.44-4.82l.56-.14.15.55a27.87,27.87,0,0,0,1.59,4.16l.12.25-.12.25a27.32,27.32,0,0,0-1.59,4.17Zm-.81-8.51c-3.11.88-5,2.22-5,3.55s1.91,2.67,5,3.55a28.33,28.33,0,0,1,1.38-3.55A27.75,27.75,0,0,1,294.09,349.08Zm14.76,8.51-.15-.54a26.93,26.93,0,0,0-1.59-4.17l-.12-.25.12-.25a25.84,25.84,0,0,0,1.59-4.16l.15-.55.56.14c4.1,1,6.44,2.79,6.44,4.82s-2.35,3.79-6.44,4.82Zm-.57-5a27.81,27.81,0,0,1,1.38,3.55c3.12-.88,5-2.22,5-3.55s-1.91-2.67-5-3.55A28.33,28.33,0,0,1,308.28,352.63Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b834dfc7-277f-4c9e-a656-71c79c5ef852"
      d="M294.08,349.08l-.15-.55c-1.16-4.06-.81-7,1-8s4.5.18,7.4,3.16l.4.41-.4.41a27.55,27.55,0,0,0-2.82,3.45l-.16.23-.27,0a27.38,27.38,0,0,0-4.41.71Zm2.21-7.72a1.56,1.56,0,0,0-.82.2c-1.16.66-1.36,3-.57,6.11a30.12,30.12,0,0,1,3.77-.58,28.37,28.37,0,0,1,2.39-3C299.24,342.36,297.52,341.36,296.29,341.36Zm11.17,23.71c-1.66,0-3.79-1.25-6-3.52l-.4-.4.4-.41a27.65,27.65,0,0,0,2.82-3.46l.16-.22.27,0a26.48,26.48,0,0,0,4.41-.71l.55-.14.15.55c1.15,4.06.8,7-1,8A2.72,2.72,0,0,1,307.46,365.07Zm-4.77-3.93c1.82,1.77,3.54,2.77,4.77,2.77a1.66,1.66,0,0,0,.82-.2c1.16-.67,1.37-3,.57-6.13a28.41,28.41,0,0,1-3.77.59A29.57,29.57,0,0,1,302.69,361.14Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b834dfc7-277f-4c9e-a656-71c79c5ef852"
      d="M309.67,349.08l-.55-.14a26.48,26.48,0,0,0-4.41-.71l-.27,0-.16-.22a28.39,28.39,0,0,0-2.82-3.46l-.4-.4.4-.41c2.9-3,5.68-4.16,7.4-3.16s2.12,3.92,1,8Zm-4.59-2a30.1,30.1,0,0,1,3.77.58c.79-3.13.59-5.45-.57-6.11s-3.31.35-5.59,2.57A28.37,28.37,0,0,1,305.08,347.09Zm-8.79,18a2.75,2.75,0,0,1-1.4-.35c-1.77-1-2.12-3.93-1-8l.15-.55.55.14A27.29,27.29,0,0,0,299,357l.27,0,.16.22a26.79,26.79,0,0,0,2.82,3.46l.4.41-.4.4C300.08,363.82,298,365.07,296.29,365.07Zm-1.39-7.48c-.79,3.13-.59,5.45.57,6.12s3.31-.36,5.59-2.57a29.57,29.57,0,0,1-2.39-3A30.12,30.12,0,0,1,294.9,357.59Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b834dfc7-277f-4c9e-a656-71c79c5ef852"
      d="M301.88,358.32c-1,0-1.95,0-2.94-.12l-.27,0-.16-.22c-.56-.81-1.08-1.63-1.57-2.47s-.94-1.72-1.36-2.6l-.11-.25.11-.25c.42-.88.87-1.75,1.36-2.59s1-1.67,1.57-2.47l.16-.23.27,0a33.85,33.85,0,0,1,5.87,0l.27,0,.15.23a35.46,35.46,0,0,1,2.94,5.06l.11.25-.11.25a34.12,34.12,0,0,1-2.94,5.07l-.15.22-.27,0C303.82,358.28,302.83,358.32,301.88,358.32Zm-2.57-1.26a33.79,33.79,0,0,0,5.13,0,34.13,34.13,0,0,0,2.55-4.43,32.9,32.9,0,0,0-2.55-4.42,33.79,33.79,0,0,0-5.13,0,32.9,32.9,0,0,0-2.55,4.42A34.13,34.13,0,0,0,299.31,357.06Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M40.75,428.49h-.91L43.28,419h.93l3.44,9.45h-.91l-3-8.33h-.07Zm.65-3.62h4.69v.78H41.4Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M51.65,428.64a2.59,2.59,0,0,1-1.53-.46,3,3,0,0,1-1-1.29,5.17,5.17,0,0,1,0-3.84,2.93,2.93,0,0,1,1-1.28,2.59,2.59,0,0,1,1.53-.46,2.06,2.06,0,0,1,1.77.79,3.63,3.63,0,0,1,.4.66h.07V419h.83v9.45h-.8v-1.31h-.1a3.51,3.51,0,0,1-.41.66,2,2,0,0,1-.69.57A2.33,2.33,0,0,1,51.65,428.64Zm.1-.75a1.85,1.85,0,0,0,1.17-.38,2.32,2.32,0,0,0,.74-1,4.51,4.51,0,0,0,0-3,2.24,2.24,0,0,0-.74-1,2,2,0,0,0-2.37,0,2.36,2.36,0,0,0-.74,1,4,4,0,0,0-.25,1.47,4.12,4.12,0,0,0,.25,1.49,2.44,2.44,0,0,0,.74,1.06A1.88,1.88,0,0,0,51.75,427.89Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M59.83,428.64a2.88,2.88,0,0,1-1.63-.46,3.3,3.3,0,0,1-1.1-1.29,4.43,4.43,0,0,1-.4-1.91,4.48,4.48,0,0,1,.4-1.92,3.17,3.17,0,0,1,1.1-1.29,3.1,3.1,0,0,1,3.25,0,3.24,3.24,0,0,1,1.1,1.29A4.48,4.48,0,0,1,63,425a4.43,4.43,0,0,1-.4,1.91,3.3,3.3,0,0,1-1.1,1.29A2.87,2.87,0,0,1,59.83,428.64Zm0-.75a2,2,0,0,0,1.25-.4,2.36,2.36,0,0,0,.77-1.05,4.08,4.08,0,0,0,0-2.92,2.4,2.4,0,0,0-.77-1.06,2,2,0,0,0-1.25-.41,2,2,0,0,0-1.25.41,2.34,2.34,0,0,0-.78,1.06,4.08,4.08,0,0,0,0,2.92,2.36,2.36,0,0,0,.77,1.05A2,2,0,0,0,59.83,427.89Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M64.92,428.49V419h.83v3.72h.07a3.14,3.14,0,0,1,.4-.66,2.31,2.31,0,0,1,.69-.57,2.28,2.28,0,0,1,1.08-.22,2.59,2.59,0,0,1,1.53.46,3,3,0,0,1,1,1.28,4.76,4.76,0,0,1,.37,1.92,4.6,4.6,0,0,1-.37,1.92,3.08,3.08,0,0,1-1,1.29,2.59,2.59,0,0,1-1.53.46,2.33,2.33,0,0,1-1.08-.23,2,2,0,0,1-.69-.57,3.51,3.51,0,0,1-.41-.66h-.1v1.31Zm.81-3.54a4.17,4.17,0,0,0,.26,1.52,2.32,2.32,0,0,0,.74,1,2,2,0,0,0,2.36,0,2.38,2.38,0,0,0,.75-1.06,4.12,4.12,0,0,0,.25-1.49,4,4,0,0,0-.25-1.47,2.36,2.36,0,0,0-.74-1,2,2,0,0,0-2.38,0,2.3,2.3,0,0,0-.73,1A4.14,4.14,0,0,0,65.73,425Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M75.59,428.64a3,3,0,0,1-2.84-1.75,4.82,4.82,0,0,1,0-3.8,3.23,3.23,0,0,1,1.1-1.31,2.76,2.76,0,0,1,1.62-.47,3,3,0,0,1,1.11.21,2.65,2.65,0,0,1,1,.64,3.11,3.11,0,0,1,.68,1.08,4.5,4.5,0,0,1,.25,1.55v.4H72.93v-.72h4.7a2.72,2.72,0,0,0-.28-1.23,2.15,2.15,0,0,0-.76-.87,2.13,2.13,0,0,0-2.31,0,2.43,2.43,0,0,0-.8,1,2.91,2.91,0,0,0-.29,1.29v.43a3.43,3.43,0,0,0,.29,1.48,2.2,2.2,0,0,0,.83,1,2.34,2.34,0,0,0,1.28.35,2.16,2.16,0,0,0,.88-.16,1.82,1.82,0,0,0,.65-.42,2,2,0,0,0,.39-.58l.78.25a2.34,2.34,0,0,1-.53.83,2.67,2.67,0,0,1-.91.6A3.15,3.15,0,0,1,75.59,428.64Z"
      transform="translate(-5.07 -32.75)"
    />
    <path d="M85,419v9.45H84.1V419Z" transform="translate(-5.07 -32.75)" />
    <path d="M88.12,419v9.45H87.3V419Z" transform="translate(-5.07 -32.75)" />
    <path d="M91.12,419v9.45h-.83V419Z" transform="translate(-5.07 -32.75)" />
    <path
      d="M97.79,425.78V421.4h.82v7.09h-.82v-1.17h-.06a2.19,2.19,0,0,1-.79.9,2.25,2.25,0,0,1-1.32.37,2.39,2.39,0,0,1-1.21-.3,2.14,2.14,0,0,1-.83-.88,3,3,0,0,1-.29-1.41v-4.6h.82V426a1.8,1.8,0,0,0,.49,1.33,1.64,1.64,0,0,0,1.25.51,2.06,2.06,0,0,0,.93-.23,2,2,0,0,0,.72-.68A1.92,1.92,0,0,0,97.79,425.78Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M105.55,423l-.76.21a1.91,1.91,0,0,0-.31-.58,1.57,1.57,0,0,0-.53-.41,1.9,1.9,0,0,0-.8-.15,2,2,0,0,0-1.19.34,1.07,1.07,0,0,0-.46.88,1,1,0,0,0,.32.75,2.51,2.51,0,0,0,1,.45l1.08.26a2.55,2.55,0,0,1,1.33.69,1.62,1.62,0,0,1,.45,1.18,1.78,1.78,0,0,1-.33,1.06,2.22,2.22,0,0,1-.93.74,3.45,3.45,0,0,1-1.37.26,2.89,2.89,0,0,1-1.72-.47,2.11,2.11,0,0,1-.86-1.35l.79-.2a1.57,1.57,0,0,0,.6,1,1.91,1.91,0,0,0,1.17.32,2.16,2.16,0,0,0,1.31-.36,1.12,1.12,0,0,0,.49-.92,1,1,0,0,0-.29-.73,1.78,1.78,0,0,0-.87-.43l-1.15-.28a2.61,2.61,0,0,1-1.37-.7,1.65,1.65,0,0,1-.44-1.19,1.74,1.74,0,0,1,.31-1,2.2,2.2,0,0,1,.88-.7,3,3,0,0,1,1.28-.25,2.48,2.48,0,0,1,1.55.44A2.45,2.45,0,0,1,105.55,423Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M110.24,421.4v.72h-3.38v-.72Zm-2.33-1.7h.83v6.94a1.37,1.37,0,0,0,.15.7.89.89,0,0,0,.4.35,1.31,1.31,0,0,0,.52.11l.28,0,.2,0,.18.75-.3.08a1.8,1.8,0,0,1-.43,0,2,2,0,0,1-.87-.2,1.71,1.71,0,0,1-.69-.59,1.68,1.68,0,0,1-.27-1Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M112.11,428.49V421.4h.79v1.09H113a1.83,1.83,0,0,1,.75-.86,2.2,2.2,0,0,1,1.19-.33h.44v.84l-.2,0-.3,0a2.08,2.08,0,0,0-1,.23,1.77,1.77,0,0,0-.93,1.58v4.58Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M118.72,428.66a2.64,2.64,0,0,1-1.17-.26,2,2,0,0,1-.85-.73,2,2,0,0,1-.31-1.16,1.82,1.82,0,0,1,.2-.88,1.58,1.58,0,0,1,.56-.59,2.94,2.94,0,0,1,.86-.37,8.27,8.27,0,0,1,1.1-.21l1-.13a1.66,1.66,0,0,0,.63-.17.41.41,0,0,0,.22-.39v-.16a1.52,1.52,0,0,0-.43-1.15,1.71,1.71,0,0,0-1.24-.42,2.17,2.17,0,0,0-1.25.34,1.9,1.9,0,0,0-.68.8l-.78-.29a2.37,2.37,0,0,1,.67-.93,2.63,2.63,0,0,1,.95-.5,3.82,3.82,0,0,1,1.06-.15,3.64,3.64,0,0,1,.85.1,2.44,2.44,0,0,1,.82.37,1.81,1.81,0,0,1,.61.72,2.58,2.58,0,0,1,.24,1.18v4.81H121v-1.12h0a2.4,2.4,0,0,1-.44.61,2.28,2.28,0,0,1-.73.49A2.7,2.7,0,0,1,118.72,428.66Zm.11-.76a2.1,2.1,0,0,0,1.14-.29,2,2,0,0,0,.74-.77,2.26,2.26,0,0,0,.26-1.07v-1a.88.88,0,0,1-.31.16l-.49.12-.56.09-.5.06a4.25,4.25,0,0,0-1,.24,1.35,1.35,0,0,0-.65.43,1.05,1.05,0,0,0-.23.7,1.21,1.21,0,0,0,.46,1A1.84,1.84,0,0,0,118.83,427.9Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M126.77,421.4v.72h-3.39v-.72Zm-2.33-1.7h.83v6.94a1.37,1.37,0,0,0,.15.7.8.8,0,0,0,.4.35,1.27,1.27,0,0,0,.52.11l.28,0,.2,0,.17.75-.29.08a1.83,1.83,0,0,1-.44,0,2,2,0,0,1-.87-.2,1.75,1.75,0,0,1-.68-.59,1.68,1.68,0,0,1-.27-1Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M131.31,428.64a2.87,2.87,0,0,1-1.62-.46,3.3,3.3,0,0,1-1.1-1.29,4.43,4.43,0,0,1-.4-1.91,4.48,4.48,0,0,1,.4-1.92,3.17,3.17,0,0,1,1.1-1.29,2.87,2.87,0,0,1,1.62-.46,2.83,2.83,0,0,1,1.62.46,3.19,3.19,0,0,1,1.11,1.29,4.48,4.48,0,0,1,.4,1.92,4.43,4.43,0,0,1-.4,1.91,3.3,3.3,0,0,1-1.1,1.29A2.88,2.88,0,0,1,131.31,428.64Zm0-.75a2,2,0,0,0,1.26-.4,2.36,2.36,0,0,0,.77-1.05,4.08,4.08,0,0,0,0-2.92,2.4,2.4,0,0,0-.77-1.06,2,2,0,0,0-1.26-.41,2,2,0,0,0-1.24.41,2.34,2.34,0,0,0-.78,1.06,4.08,4.08,0,0,0,0,2.92,2.36,2.36,0,0,0,.77,1.05A2,2,0,0,0,131.31,427.89Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M136.24,428.49V421.4h.8v1.09h.06a1.8,1.8,0,0,1,.74-.86,2.21,2.21,0,0,1,1.2-.33h.43v.84l-.19,0-.3,0a2.13,2.13,0,0,0-1,.23,1.77,1.77,0,0,0-.68.65,1.71,1.71,0,0,0-.25.93v4.58Z"
      transform="translate(-5.07 -32.75)"
    />
    <g id="a990107b-32a5-491c-99ec-62364c458b7f" data-name="Layer 2">
      <g id="bdde8f22-4191-408c-88c0-d1bfabcdee86" data-name="Surfaces">
        <g
          id="ada7c411-f511-432f-98e5-e39b5e2c635b"
          data-name="Drawing Surface"
        >
          <g
            id="beffa346-ad36-4d69-a81e-fd4584033812"
            data-name="Outline no shadow"
          >
            <path
              class="bcb11178-8b52-410e-aa38-0ecc0f3d9437"
              d="M16.34,414H28.27a3.27,3.27,0,0,1,3.27,3.27v11.46A3.27,3.27,0,0,1,28.27,432H16.34a3.27,3.27,0,0,1-3.27-3.27V417.23A3.27,3.27,0,0,1,16.34,414Z"
              transform="translate(-5.07 -32.75)"
            />
          </g>
        </g>
      </g>
      <g
        id="a6d295d4-cf87-4b0b-90b8-8c67e46e9e9c"
        data-name="Outlined Mnemonics Logos"
      >
        <g id="bd15843f-c5f9-4218-8b10-07d70ebd4fe1" data-name="Ai">
          <path
            class="b2b89b4e-5594-4105-a0a9-2c9596b8ef68"
            d="M21.92,426H18.58l-.69,2.11a.15.15,0,0,1-.17.12H16c-.1,0-.12-.05-.1-.16l2.9-8.33c0-.09.05-.19.09-.3a2.78,2.78,0,0,0,0-.58.09.09,0,0,1,.08-.1h2.32c.08,0,.11,0,.12.07l3.28,9.26c0,.1,0,.14-.09.14H22.8a.13.13,0,0,1-.14-.1Zm-2.82-1.83h2.28c-.05-.19-.12-.41-.2-.65s-.17-.5-.25-.77l-.26-.83c-.09-.27-.17-.54-.24-.8s-.13-.48-.2-.7h0c-.08.39-.18.77-.3,1.16s-.27.88-.42,1.33-.26.88-.39,1.26Z"
            transform="translate(-5.07 -32.75)"
          />
          <path
            class="b2b89b4e-5594-4105-a0a9-2c9596b8ef68"
            d="M26.73,420.26a1.1,1.1,0,0,1-.8-.32,1.14,1.14,0,0,1-.3-.83,1,1,0,0,1,.32-.8,1.18,1.18,0,0,1,.8-.31,1.11,1.11,0,0,1,.82.31,1.18,1.18,0,0,1,.29.8,1.11,1.11,0,0,1-.31.83A1.08,1.08,0,0,1,26.73,420.26Zm-1,7.8v-6.92c0-.09,0-.13.12-.13h1.78c.08,0,.12.05.12.13v6.92c0,.1,0,.14-.12.14H25.86A.13.13,0,0,1,25.72,428.06Z"
            transform="translate(-5.07 -32.75)"
          />
        </g>
      </g>
    </g>
    <path
      d="M32.31,462.87h-.9l3.44-9.45h.92l3.44,9.45h-.9l-3-8.33h-.06Zm.66-3.62h4.69V460H33Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M43.21,463a2.6,2.6,0,0,1-1.52-.46,3.1,3.1,0,0,1-1-1.29,5.33,5.33,0,0,1,0-3.85,3.13,3.13,0,0,1,1-1.28,2.67,2.67,0,0,1,1.54-.46,2.26,2.26,0,0,1,1.07.23,2,2,0,0,1,.69.57,3.09,3.09,0,0,1,.4.65h.08v-3.71h.82v9.45h-.8v-1.32h-.1a3.2,3.2,0,0,1-.4.67,2.36,2.36,0,0,1-.7.57A2.33,2.33,0,0,1,43.21,463Zm.1-.75a1.85,1.85,0,0,0,1.17-.38,2.3,2.3,0,0,0,.74-1,4,4,0,0,0,.26-1.52,4.07,4.07,0,0,0-.25-1.5,2.34,2.34,0,0,0-.74-1,2.05,2.05,0,0,0-2.38,0,2.38,2.38,0,0,0-.74,1,4.19,4.19,0,0,0-.25,1.47,4.12,4.12,0,0,0,.26,1.49,2.42,2.42,0,0,0,.74,1.06A1.83,1.83,0,0,0,43.31,462.27Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M51.39,463a2.88,2.88,0,0,1-1.62-.47,3.09,3.09,0,0,1-1.1-1.29,4.25,4.25,0,0,1-.4-1.9,4.31,4.31,0,0,1,.4-1.92,3.09,3.09,0,0,1,1.1-1.29,3,3,0,0,1,3.24,0,3.17,3.17,0,0,1,1.1,1.29,4.31,4.31,0,0,1,.4,1.92,4.25,4.25,0,0,1-.4,1.9,3.09,3.09,0,0,1-1.1,1.29A2.88,2.88,0,0,1,51.39,463Zm0-.75a2,2,0,0,0,1.25-.4,2.56,2.56,0,0,0,.78-1.06,3.78,3.78,0,0,0,.26-1.45,3.83,3.83,0,0,0-.26-1.46,2.59,2.59,0,0,0-.78-1.07,2.15,2.15,0,0,0-2.5,0,2.59,2.59,0,0,0-.78,1.07,3.83,3.83,0,0,0-.26,1.46,3.78,3.78,0,0,0,.26,1.45,2.56,2.56,0,0,0,.78,1.06A2,2,0,0,0,51.39,462.27Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M56.49,462.87v-9.45h.82v3.71h.08a2.72,2.72,0,0,1,.4-.65,2,2,0,0,1,.68-.57,2.33,2.33,0,0,1,1.08-.23,2.66,2.66,0,0,1,1.53.46,3.05,3.05,0,0,1,1,1.28,4.6,4.6,0,0,1,.37,1.92,4.78,4.78,0,0,1-.36,1.93,3.1,3.1,0,0,1-1,1.29,2.65,2.65,0,0,1-1.52.46,2.3,2.3,0,0,1-1.08-.23,2.36,2.36,0,0,1-.7-.57,3.2,3.2,0,0,1-.4-.67h-.1v1.32Zm.81-3.54a4.21,4.21,0,0,0,.25,1.52,2.46,2.46,0,0,0,.74,1,2,2,0,0,0,2.37,0,2.42,2.42,0,0,0,.74-1.06,4.12,4.12,0,0,0,.26-1.49,4.19,4.19,0,0,0-.25-1.47,2.32,2.32,0,0,0-.75-1,2,2,0,0,0-2.37,0,2.34,2.34,0,0,0-.74,1A4.07,4.07,0,0,0,57.3,459.33Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M67.16,463a3.1,3.1,0,0,1-1.72-.47,3,3,0,0,1-1.12-1.28,4.28,4.28,0,0,1-.4-1.9,4.32,4.32,0,0,1,.4-1.91,3.18,3.18,0,0,1,1.09-1.3,2.81,2.81,0,0,1,1.62-.48,3,3,0,0,1,1.11.21,2.93,2.93,0,0,1,1,.64,3,3,0,0,1,.67,1.09,4.21,4.21,0,0,1,.25,1.54v.41H64.49v-.73h4.7a2.77,2.77,0,0,0-.27-1.23,2.21,2.21,0,0,0-.77-.86,2,2,0,0,0-1.12-.32,2,2,0,0,0-1.19.36,2.56,2.56,0,0,0-.8.95,3,3,0,0,0-.29,1.29v.44a3.54,3.54,0,0,0,.29,1.47,2.23,2.23,0,0,0,2.12,1.33,2.38,2.38,0,0,0,.88-.16,2.09,2.09,0,0,0,.64-.42,2.05,2.05,0,0,0,.4-.59l.78.26a2.45,2.45,0,0,1-.53.82,2.74,2.74,0,0,1-.92.61A3.24,3.24,0,0,1,67.16,463Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M75.66,462.87v-9.45h3a3.33,3.33,0,0,1,1.69.38,2.57,2.57,0,0,1,1,1.05,3.13,3.13,0,0,1,.34,1.48,3.17,3.17,0,0,1-.34,1.49,2.57,2.57,0,0,1-1,1.05,3.25,3.25,0,0,1-1.67.39h-2.4v-.78h2.38a2.43,2.43,0,0,0,1.23-.28,1.84,1.84,0,0,0,.73-.76,2.48,2.48,0,0,0,.24-1.11,2.44,2.44,0,0,0-.24-1.1,1.78,1.78,0,0,0-.73-.76,2.49,2.49,0,0,0-1.25-.28H76.53v8.68Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M84.44,458.44v4.43h-.82v-9.45h.82v3.47h.08a2,2,0,0,1,.77-.88,2.54,2.54,0,0,1,1.33-.32,2.66,2.66,0,0,1,1.26.29,2,2,0,0,1,.85.87,3,3,0,0,1,.31,1.43v4.59h-.83v-4.54a1.9,1.9,0,0,0-.49-1.39,1.75,1.75,0,0,0-1.31-.5,2.07,2.07,0,0,0-1,.24,1.72,1.72,0,0,0-.7.69A2.15,2.15,0,0,0,84.44,458.44Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M94,463a2.88,2.88,0,0,1-1.62-.47,3.09,3.09,0,0,1-1.1-1.29,4.25,4.25,0,0,1-.4-1.9,4.31,4.31,0,0,1,.4-1.92,3.09,3.09,0,0,1,1.1-1.29,3,3,0,0,1,3.24,0,3.09,3.09,0,0,1,1.1,1.29,4.31,4.31,0,0,1,.4,1.92,4.39,4.39,0,0,1-.39,1.9,3.19,3.19,0,0,1-1.11,1.29A2.86,2.86,0,0,1,94,463Zm0-.75a2,2,0,0,0,1.25-.4,2.56,2.56,0,0,0,.78-1.06,3.78,3.78,0,0,0,.27-1.45A3.83,3.83,0,0,0,96,457.9a2.59,2.59,0,0,0-.78-1.07,2.15,2.15,0,0,0-2.5,0,2.59,2.59,0,0,0-.78,1.07,4,4,0,0,0-.26,1.46,4,4,0,0,0,.26,1.45,2.56,2.56,0,0,0,.78,1.06A2,2,0,0,0,94,462.27Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M101.68,455.78v.71H98.3v-.71Zm-2.33-1.7h.83V461a1.33,1.33,0,0,0,.15.69.91.91,0,0,0,.4.36,1.3,1.3,0,0,0,.52.1,1.19,1.19,0,0,0,.28,0l.2,0,.18.74-.3.09a2.42,2.42,0,0,1-.43,0,2,2,0,0,1-.87-.2,1.71,1.71,0,0,1-.69-.59,1.69,1.69,0,0,1-.27-1Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M106.23,463a2.88,2.88,0,0,1-1.62-.47,3.19,3.19,0,0,1-1.11-1.29,4.39,4.39,0,0,1-.39-1.9,4.45,4.45,0,0,1,.39-1.92,3.19,3.19,0,0,1,1.11-1.29,3,3,0,0,1,3.24,0,3.17,3.17,0,0,1,1.1,1.29,4.31,4.31,0,0,1,.4,1.92,4.25,4.25,0,0,1-.4,1.9,3.09,3.09,0,0,1-1.1,1.29A2.88,2.88,0,0,1,106.23,463Zm0-.75a2,2,0,0,0,1.25-.4,2.56,2.56,0,0,0,.78-1.06,4,4,0,0,0,.26-1.45,4,4,0,0,0-.26-1.46,2.59,2.59,0,0,0-.78-1.07,2.15,2.15,0,0,0-2.5,0,2.59,2.59,0,0,0-.78,1.07,3.83,3.83,0,0,0-.27,1.46,3.78,3.78,0,0,0,.27,1.45,2.56,2.56,0,0,0,.78,1.06A2,2,0,0,0,106.23,462.27Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M115.92,457.33l-.75.22a1.92,1.92,0,0,0-.32-.58,1.37,1.37,0,0,0-.53-.41,1.87,1.87,0,0,0-.8-.15,1.9,1.9,0,0,0-1.18.34,1.05,1.05,0,0,0-.47.88,1,1,0,0,0,.32.74,2.3,2.3,0,0,0,1,.45l1.07.26a2.7,2.7,0,0,1,1.33.69A1.65,1.65,0,0,1,116,461a1.81,1.81,0,0,1-.33,1.07,2.14,2.14,0,0,1-.93.73,3.25,3.25,0,0,1-1.37.27,2.93,2.93,0,0,1-1.71-.47,2.12,2.12,0,0,1-.87-1.36l.79-.19a1.6,1.6,0,0,0,.6,1,2,2,0,0,0,1.18.32,2.09,2.09,0,0,0,1.3-.37,1.09,1.09,0,0,0,.49-.92,1,1,0,0,0-.29-.72,1.7,1.7,0,0,0-.86-.43l-1.16-.28a2.69,2.69,0,0,1-1.37-.7,1.72,1.72,0,0,1-.44-1.19,1.66,1.66,0,0,1,.32-1,2.1,2.1,0,0,1,.87-.7,3.08,3.08,0,0,1,1.28-.26,2.55,2.55,0,0,1,1.56.44A2.53,2.53,0,0,1,115.92,457.33Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M118.64,458.44v4.43h-.82v-9.45h.82v3.47h.08a2,2,0,0,1,.77-.88,2.54,2.54,0,0,1,1.33-.32,2.66,2.66,0,0,1,1.26.29,2.1,2.1,0,0,1,.85.87,3.09,3.09,0,0,1,.31,1.43v4.59h-.83v-4.54a1.9,1.9,0,0,0-.49-1.39,1.75,1.75,0,0,0-1.31-.5,2.07,2.07,0,0,0-1,.24,1.72,1.72,0,0,0-.7.69A2.15,2.15,0,0,0,118.64,458.44Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M128.16,463a2.88,2.88,0,0,1-1.62-.47,3.09,3.09,0,0,1-1.1-1.29,4.25,4.25,0,0,1-.4-1.9,4.31,4.31,0,0,1,.4-1.92,3.09,3.09,0,0,1,1.1-1.29,3,3,0,0,1,3.24,0,3.09,3.09,0,0,1,1.1,1.29,4.31,4.31,0,0,1,.4,1.92,4.25,4.25,0,0,1-.4,1.9,3.09,3.09,0,0,1-1.1,1.29A2.88,2.88,0,0,1,128.16,463Zm0-.75a2,2,0,0,0,1.25-.4,2.56,2.56,0,0,0,.78-1.06,3.78,3.78,0,0,0,.27-1.45,3.83,3.83,0,0,0-.27-1.46,2.59,2.59,0,0,0-.78-1.07,2.15,2.15,0,0,0-2.5,0,2.59,2.59,0,0,0-.78,1.07,4,4,0,0,0-.26,1.46,4,4,0,0,0,.26,1.45,2.56,2.56,0,0,0,.78,1.06A2,2,0,0,0,128.16,462.27Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M133.07,465.53v-9.75h.8v1.35h.1a3.16,3.16,0,0,1,.41-.65,2.06,2.06,0,0,1,1.76-.8,2.69,2.69,0,0,1,1.54.46,3,3,0,0,1,1,1.28,5.19,5.19,0,0,1,0,3.85,3.16,3.16,0,0,1-1,1.29,2.66,2.66,0,0,1-1.53.46,2.1,2.1,0,0,1-1.77-.8,3.27,3.27,0,0,1-.41-.67h-.07v4Zm.82-6.2a4.21,4.21,0,0,0,.25,1.52,2.38,2.38,0,0,0,.74,1,2,2,0,0,0,2.37,0,2.5,2.5,0,0,0,.74-1.06,4.12,4.12,0,0,0,.25-1.49,4,4,0,0,0-.25-1.47,2.3,2.3,0,0,0-.74-1,1.84,1.84,0,0,0-1.2-.39,1.89,1.89,0,0,0-1.18.37,2.25,2.25,0,0,0-.73,1A4.07,4.07,0,0,0,133.89,459.33Z"
      transform="translate(-5.07 -32.75)"
    />
    <g id="a338eb1e-940f-43d0-8fcf-8e9e9bbba541" data-name="Layer 2">
      <g id="b91d3b76-749f-4652-a06e-75a49af2bf69" data-name="Surfaces">
        <g id="ae73cd0f-0687-4de4-9bb5-945508e02203" data-name="Photo Surface">
          <g
            id="ff698652-83b6-4842-ab43-326a79b5d842"
            data-name="Outline no shadow"
          >
            <path
              class="f89c03f3-ae52-471e-a58f-c01049733a93"
              d="M8.34,449.55H20.27a3.27,3.27,0,0,1,3.27,3.27v11.46a3.27,3.27,0,0,1-3.27,3.27H8.34a3.27,3.27,0,0,1-3.27-3.27V452.82A3.27,3.27,0,0,1,8.34,449.55Z"
              transform="translate(-5.07 -32.75)"
            />
          </g>
        </g>
      </g>
      <g
        id="b0b124d9-7468-4290-8b47-4d42e7769264"
        data-name="Outlined Mnemonics Logos"
      >
        <g id="aec3aae8-bc72-4bab-ac1e-006a2695ad71" data-name="Ps">
          <path
            class="fdfd7200-8c70-4e4a-a25f-499e39b3ce9c"
            d="M9.23,462.18v-7.92c0-.05,0-.08.08-.08h.43l.58,0h1.37a4.53,4.53,0,0,1,1.58.24,2.61,2.61,0,0,1,1,.63,2.39,2.39,0,0,1,.57.88,3,3,0,0,1,.17,1,2.71,2.71,0,0,1-.46,1.63,2.57,2.57,0,0,1-1.24.94,4.8,4.8,0,0,1-1.73.26H10.9v2.47a.11.11,0,0,1-.09.11H9.32C9.26,462.28,9.23,462.24,9.23,462.18Zm1.68-6.52v2.58l.3,0h.4a3,3,0,0,0,.89-.14,1.31,1.31,0,0,0,.63-.41,1.18,1.18,0,0,0,.24-.79,1.36,1.36,0,0,0-.18-.68,1.11,1.11,0,0,0-.54-.44,2.3,2.3,0,0,0-.91-.16h-.52a1.31,1.31,0,0,0-.31,0Z"
            transform="translate(-5.07 -32.75)"
          />
          <path
            class="fdfd7200-8c70-4e4a-a25f-499e39b3ce9c"
            d="M19.84,457.78a2.67,2.67,0,0,0-.73-.27,4.22,4.22,0,0,0-.87-.1,1.82,1.82,0,0,0-.46.06.38.38,0,0,0-.24.15.51.51,0,0,0-.06.21.32.32,0,0,0,.08.2,1.09,1.09,0,0,0,.26.21l.55.25a5.79,5.79,0,0,1,1.18.56,1.78,1.78,0,0,1,.61.64,1.82,1.82,0,0,1,.18.79,1.88,1.88,0,0,1-.3,1,2,2,0,0,1-.87.68,3.49,3.49,0,0,1-1.39.25,5.16,5.16,0,0,1-1-.1,3.16,3.16,0,0,1-.79-.25.15.15,0,0,1-.08-.15v-1.33a.08.08,0,0,1,0-.07s0,0,.07,0a3.26,3.26,0,0,0,1,.38,3.44,3.44,0,0,0,.91.12,1.47,1.47,0,0,0,.64-.11.35.35,0,0,0,.2-.32.41.41,0,0,0-.18-.31,3.06,3.06,0,0,0-.75-.36,4.75,4.75,0,0,1-1.1-.56,2.14,2.14,0,0,1-.58-.65,1.68,1.68,0,0,1-.18-.78,1.82,1.82,0,0,1,.26-.94,2,2,0,0,1,.81-.7,2.92,2.92,0,0,1,1.36-.27,6.2,6.2,0,0,1,1,.07,2.91,2.91,0,0,1,.66.17.18.18,0,0,1,.08.07s0,.06,0,.1v1.25a.09.09,0,0,1,0,.08A.32.32,0,0,1,19.84,457.78Z"
            transform="translate(-5.07 -32.75)"
          />
        </g>
      </g>
    </g>
    <path
      class="a5bc420a-77d1-4260-b8e4-ecc2bb7a7d0f"
      d="M153.6,421.44h-.08a4.1,4.1,0,0,0,0,8.2h.08a4.1,4.1,0,0,0,0-8.2Zm-.08,70.68v5.78a10.92,10.92,0,0,0,10.92,10.93H201.5a10.78,10.78,0,0,1,11,10.67V523m-59-39.08V463.38m0-8.2V429.64m0-8.2v-2.22a10.92,10.92,0,0,1,10.92-10.93H189.5"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="a5bc420a-77d1-4260-b8e4-ecc2bb7a7d0f"
      d="M157.7,488a4.11,4.11,0,0,1-4.1,4.1h-.08a4.1,4.1,0,0,1,0-8.2h.08A4.11,4.11,0,0,1,157.7,488Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="a5bc420a-77d1-4260-b8e4-ecc2bb7a7d0f"
      d="M157.7,459.28a4.1,4.1,0,0,1-4.1,4.1h-.08a4.1,4.1,0,0,1,0-8.2h.08A4.11,4.11,0,0,1,157.7,459.28Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M80.27,492.94h-.9l3.43-9.45h.93l3.44,9.45h-.91l-3-8.33h-.07Zm.65-3.62h4.69v.77H80.92Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M91.17,493.09a2.59,2.59,0,0,1-1.53-.46,3.08,3.08,0,0,1-1-1.29,5.17,5.17,0,0,1,0-3.84,3,3,0,0,1,1-1.29,2.66,2.66,0,0,1,1.53-.46,2.33,2.33,0,0,1,1.08.23,2,2,0,0,1,.69.57,3.57,3.57,0,0,1,.4.65h.07v-3.71h.83v9.45h-.8v-1.31h-.1a3.51,3.51,0,0,1-.41.66,2.19,2.19,0,0,1-.69.57A2.33,2.33,0,0,1,91.17,493.09Zm.1-.75a1.85,1.85,0,0,0,1.17-.38,2.38,2.38,0,0,0,.74-1,4.21,4.21,0,0,0,.26-1.52,4.06,4.06,0,0,0-.26-1.5,2.27,2.27,0,0,0-.74-1,1.9,1.9,0,0,0-1.17-.37,1.84,1.84,0,0,0-1.2.39,2.3,2.3,0,0,0-.74,1,4,4,0,0,0-.25,1.47,4.12,4.12,0,0,0,.25,1.49,2.5,2.5,0,0,0,.74,1.06A1.88,1.88,0,0,0,91.27,492.34Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M99.35,493.09a2.89,2.89,0,0,1-1.63-.47,3.17,3.17,0,0,1-1.1-1.29,4.39,4.39,0,0,1-.4-1.9,4.45,4.45,0,0,1,.4-1.92,3.17,3.17,0,0,1,1.1-1.29,3,3,0,0,1,3.25,0,3.24,3.24,0,0,1,1.1,1.29,4.45,4.45,0,0,1,.4,1.92,4.39,4.39,0,0,1-.4,1.9,3.17,3.17,0,0,1-1.1,1.29A2.88,2.88,0,0,1,99.35,493.09Zm0-.75a2,2,0,0,0,1.25-.4,2.56,2.56,0,0,0,.78-1.06,4,4,0,0,0,.26-1.45,4,4,0,0,0-.26-1.46,2.59,2.59,0,0,0-.78-1.07,2,2,0,0,0-1.25-.4,2,2,0,0,0-1.25.4,2.42,2.42,0,0,0-.78,1.07,3.83,3.83,0,0,0-.27,1.46,3.78,3.78,0,0,0,.27,1.45,2.45,2.45,0,0,0,.77,1.06A2,2,0,0,0,99.35,492.34Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M104.44,492.94v-9.45h.83v3.71h.07a3.09,3.09,0,0,1,.4-.65,2,2,0,0,1,.69-.57,2.28,2.28,0,0,1,1.08-.23,2.66,2.66,0,0,1,1.53.46,3.1,3.1,0,0,1,1,1.29,5.17,5.17,0,0,1,0,3.84,3,3,0,0,1-1,1.29,2.59,2.59,0,0,1-1.53.46,2.1,2.1,0,0,1-1.77-.8,3.51,3.51,0,0,1-.41-.66h-.1v1.31Zm.81-3.54a4.21,4.21,0,0,0,.26,1.52,2.38,2.38,0,0,0,.74,1,1.85,1.85,0,0,0,1.17.38,1.88,1.88,0,0,0,1.2-.39,2.5,2.5,0,0,0,.74-1.06,4.12,4.12,0,0,0,.25-1.49,4,4,0,0,0-.25-1.47,2.3,2.3,0,0,0-.74-1,1.88,1.88,0,0,0-1.2-.39,1.9,1.9,0,0,0-1.17.37,2.27,2.27,0,0,0-.74,1A4.06,4.06,0,0,0,105.25,489.4Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M115.11,493.09a3.14,3.14,0,0,1-1.72-.47,3,3,0,0,1-1.12-1.28,4.42,4.42,0,0,1-.39-1.9,4.46,4.46,0,0,1,.39-1.91,3.2,3.2,0,0,1,1.1-1.3,2.77,2.77,0,0,1,1.62-.48,3,3,0,0,1,1.11.21,2.68,2.68,0,0,1,1,.65,2.94,2.94,0,0,1,.68,1.08,4.48,4.48,0,0,1,.25,1.54v.41h-5.54v-.72h4.7a2.72,2.72,0,0,0-.28-1.23,2.15,2.15,0,0,0-.76-.87,2.13,2.13,0,0,0-2.31,0,2.47,2.47,0,0,0-.8.95,3,3,0,0,0-.29,1.29v.44A3.38,3.38,0,0,0,113,491a2.22,2.22,0,0,0,.83,1,2.34,2.34,0,0,0,1.28.35,2.44,2.44,0,0,0,.89-.16,2,2,0,0,0,.64-.42,1.89,1.89,0,0,0,.39-.59l.78.26a2.22,2.22,0,0,1-.53.82,2.56,2.56,0,0,1-.91.61A3.33,3.33,0,0,1,115.11,493.09Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M123.77,483.49l2.61,4.1h.06l2.62-4.1h1l-3,4.72,3,4.73h-1l-2.62-4.06h-.06l-2.61,4.06h-1l3.08-4.73-3.08-4.72Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      d="M134.49,492.94h-2.77v-9.45h2.94a4.44,4.44,0,0,1,2.31.56,3.7,3.7,0,0,1,1.48,1.62,5.72,5.72,0,0,1,.51,2.52,5.86,5.86,0,0,1-.52,2.55,3.66,3.66,0,0,1-1.53,1.63A4.79,4.79,0,0,1,134.49,492.94Zm-1.91-.77h1.86a4,4,0,0,0,2-.48,3,3,0,0,0,1.23-1.38,4.92,4.92,0,0,0,.41-2.12,5,5,0,0,0-.4-2.1,3,3,0,0,0-1.18-1.36,3.73,3.73,0,0,0-1.93-.47h-2Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="b3b0a662-b21c-4e77-a034-63b3d3ea1700"
      d="M55.34,479.15H67.27a3.27,3.27,0,0,1,3.27,3.27v11.46a3.27,3.27,0,0,1-3.27,3.27H55.34a3.27,3.27,0,0,1-3.27-3.27V482.42A3.27,3.27,0,0,1,55.34,479.15Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="a93d6778-f477-442c-86ab-305764475ff6"
      d="M61.78,483.88l-2.31,3.81,2.47,4a.18.18,0,0,1,0,.1s0,0-.08,0H60.11c-.13,0-.21,0-.27-.08-.16-.33-.33-.64-.49-1s-.34-.64-.52-1l-.56-1h0c-.16.33-.34.66-.52,1l-.52,1c-.18.33-.35.66-.53,1,0,.08-.09.09-.18.09H54.82s0,0,0,0a.11.11,0,0,1,0-.08l2.39-3.92-2.33-3.95s0-.06,0-.08,0,0,.08,0h1.74l.11,0s.05,0,.08.07c.14.33.31.66.49,1l.55,1c.19.32.36.64.52,1h0c.16-.33.33-.67.5-1l.53-1,.51-1s0-.06,0-.07a.12.12,0,0,1,.1,0h1.62a.06.06,0,0,1,.08.05s0,0,0,.06Z"
      transform="translate(-5.07 -32.75)"
    />
    <path
      class="a93d6778-f477-442c-86ab-305764475ff6"
      d="M65.34,492a3.8,3.8,0,0,1-1.66-.34,2.8,2.8,0,0,1-1.16-1,3.36,3.36,0,0,1-.42-1.75A3.14,3.14,0,0,1,63.74,486a4,4,0,0,1,1.95-.44h.16l.24,0v-2.43c0-.06,0-.09.08-.09h1.56a.09.09,0,0,1,.08.06v7.34c0,.13,0,.29,0,.46s0,.31,0,.44a.13.13,0,0,1-.08.13,6,6,0,0,1-1.25.37A7.72,7.72,0,0,1,65.34,492Zm.75-1.53v-3.39l-.21,0h-.25a1.88,1.88,0,0,0-.87.2,1.62,1.62,0,0,0-.65.57,1.66,1.66,0,0,0-.26,1,2.12,2.12,0,0,0,.13.79,1.44,1.44,0,0,0,.35.55,1.25,1.25,0,0,0,.52.31,2,2,0,0,0,.64.1l.32,0a.65.65,0,0,0,.28,0Z"
      transform="translate(-5.07 -32.75)"
    />
    <g
      id="f6816e6d-d691-4de9-a1b2-30aa13a56e47"
      data-name="Vertical Bar Mobile"
    >
      <line
        class="b41d73f9-0d13-4650-b5ea-fa9c6ebcf0f8"
        x1="207.43"
        y1="104.75"
        x2="207.43"
        y2="47.75"
      />
      <polyline
        class="b41d73f9-0d13-4650-b5ea-fa9c6ebcf0f8"
        points="207.43 156.75 207.43 202 207.43 204.75 207.43 236.75"
      />
      <polyline
        class="b41d73f9-0d13-4650-b5ea-fa9c6ebcf0f8"
        points="207.43 279.75 207.43 321.75 207.43 324.75 207.43 354.75"
      />
      <polyline
        class="b41d73f9-0d13-4650-b5ea-fa9c6ebcf0f8"
        points="207.43 500.29 207.43 497.6 207.43 490.25 207.43 486.75 207.43 441.75 207.43 439 207.43 395.75"
      />
      <line
        class="b41d73f9-0d13-4650-b5ea-fa9c6ebcf0f8"
        x1="207.43"
        y1="497.6"
        x2="207.43"
        y2="500.6"
      />
      <line
        class="e66afa13-fa46-4f1b-8bc3-061a97ff7f87"
        x1="207.43"
        y1="505.53"
        x2="207.43"
        y2="522.79"
      />
      <line
        class="b41d73f9-0d13-4650-b5ea-fa9c6ebcf0f8"
        x1="207.43"
        y1="525.25"
        x2="207.43"
        y2="528.25"
      />
    </g>
  </svg>
);

export default AboutScreen;
